<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left" style="display: inline-flex;text-decoration: underline;">
                                <h4 class="text-black h4" style="padding-right: 5px;">Tela: {{ NomeTela }} </h4>
                            </div>
                        </div>
                    </div>
                    <v-tabs v-model="tab" color="deep-purple-accent-4" bg-color="#f7f7f7">
                        <v-tab value="dados" class="tabinfo">Detalhe Pedido</v-tab>
                        <v-tab value="impressao" class="tabinfo">Impressão Pedido</v-tab>
                        <v-tab value="impressao2" class="tabinfo">Impressão Pedido v2</v-tab>
                    </v-tabs>
                    <v-window v-model="tab">
                        <v-window-item value="dados">
                            <v-form>
                                <v-container
                                    v-if="PedidosCli != '' && Cidades != '' && Estados != '' && Bairros != ''">
                                    <v-row>
                                        <v-col cols="12" md="4">
                                            <v-row>
                                                <v-col cols="12" md="12">
                                                    <v-card style="padding: 15px;" color="#f7f7f7" v-if="PedidosEndereco != ''">
                                                        <div>
                                                            <h5 class="subtitulo" style="padding-bottom: 15px;">Endereço
                                                                Entrega</h5>
                                                            <p class="texto-mensagem mb-0" style="color:#333333;">
                                                                <strong>Endereço:</strong> {{ PedidosEndereco[0].pdcEndereco
                                                                }}, {{ PedidosEndereco[0].pdcNumero }} - {{
    PedidosEndereco[0].pdcComplemento }} </p>
                                                            <p class="texto-mensagem mb-0" style="color:#333333;">
                                                                <strong>Cidade/UF:</strong> {{ Cidades.filter(x => x.cidId ==
                                                                    PedidosEndereco[0].cidId)[0].cidNome }} / {{
        Estados.filter(x => x.cetId ==
            PedidosEndereco[0].cetId)[0].cetSigla }}</p>
                                                            <p class="texto-mensagem mb-0" style="color:#333333;">
                                                                <strong>Bairro:</strong> {{ PedidosEndereco[0].pdcBairro ==
                                                                    '' ? Bairros.filter(x => x.baiId ==
                                                                        PedidosEndereco[0].baiId)[0].baiNome :
                                                                    PedidosEndereco[0].pdcBairro }} </p>
                                                            <p class="texto-mensagem mb-0" style="color:#333333;">
                                                                <strong>Obs Cliente</strong> {{ PedidosCli.pedObsCliente }}
                                                            </p>
                                                        </div>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="12">
                                                    <v-card style="padding: 25px;" color="#f7f7f7">
                                                        <div>
                                                            <h5 class="subtitulo" style="padding-bottom: 15px;">Situação
                                                            </h5>
                                                            <v-row>
                                                                <v-select v-model="PedidosCli.pspId" label="Situação Atual"
                                                                    :items="Situacoes" item-title="pspSituacao"
                                                                    item-value="pspId" variant="underlined">
                                                                </v-select>
                                                            </v-row>
                                                            <v-row style="justify-content: center;padding-top: 15px;">
                                                                <button type="button" class="btn btn-success"
                                                                    @click="PutSituacao()">
                                                                    Alterar
                                                                </button>
                                                            </v-row>
                                                        </div>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="12">
                                                    <v-card style="padding: 25px;" color="#f7f7f7">
                                                        <div>
                                                            <h5 class="subtitulo" style="padding-bottom: 15px;">Mensagem
                                                            </h5>
                                                            <div
                                                                v-if="PedidosMensagem.length > 0">
                                                                <div v-for="pedidomensagem in PedidosMensagem"
                                                                    :key="pedidomensagem.pmsId" class="row">
                                                                    <div v-if="pedidomensagem.pmsTexto != ''" class="col">
                                                                        <div class="descricao-pedido border-top py-3">
                                                                            <div class="row">
                                                                                <div
                                                                                    class="col-12 col-md-12 align-items-center">
                                                                                    <div class="descricao-content">
                                                                                        <small>Quem envia</small><br>
                                                                                        {{ pedidomensagem.pmsNomeDe }}
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    class="col-12 col-md-12 align-items-center">
                                                                                    <div class="descricao-content">
                                                                                        <small>Presenteado(s)</small><br>
                                                                                        {{ pedidomensagem.pmsNomePara }}
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    class="col-12 col-md-12 align-items-center">
                                                                                    <div class="descricao-content">
                                                                                        <small>Telefone Recebe</small><br>
                                                                                        {{ pedidomensagem.pmsTelefoneRecebe }}
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-12 col-md-12"
                                                                                    style="padding-top: 15px;">
                                                                                    <div class="descricao-img"
                                                                                        style="padding: 10px;border: 1px solid #cccccc;height:200px;overflow-y: auto; font-size: 14px;">
                                                                                        <p v-html="pedidomensagem.pmsTexto">
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-else>
                                                                <p>Sem Mensagem</p>
                                                            </div>
                                                        </div>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" md="8">
                                            <v-row>
                                                <v-col cols="12" md="12">
                                                    <v-card style="padding: 15px;" color="#f7f7f7">
                                                        <div>
                                                            <h5 class="subtitulo" style="padding-bottom: 15px;">Informações
                                                                Pedido</h5>
                                                            <h6 class="mb-0">Pedido: {{ PedidosCli.pedId }}</h6>
                                                            <div class="pedido_dados"><strong>Cliente:</strong> {{
                                                                Cliente.cliNomeEmpresa }} - {{ Cliente.cliEmail }} - {{
        Cliente.cliWhatsapp }}</div>
                                                            <div class="pedido_dados"
                                                                v-if="PedidosCli.pedFormaPgto == 'credit_card'">
                                                                <strong>Pgto:</strong> Cartão Crédito</div>
                                                            <div class="pedido_dados"
                                                                v-else-if="PedidosCli.pedFormaPgto == 'bank_transfer'">
                                                                <strong>Pgto:</strong> PIX</div>
                                                            <div class="pedido_dados" v-else><strong>Pgto:</strong> {{
                                                                PedidosCli.pedFormaPgto }}</div>
                                                            <p class="texto-mensagem mb-0" style="color:#333333;" v-if="PedidosAgendamento != ''">
                                                                <strong>Entrega Dia:</strong> {{
                                                                    formattedDate(PedidosAgendamento[0].pagDiaEntrega) }} entre
                                                                {{ validaHora(PedidosAgendamento[0].pagHoraIni) }}:{{
                                                                    validaHora(PedidosAgendamento[0].pagMinIni) }} e {{
        validaHora(PedidosAgendamento[0].pagHoraFim) }}:{{
        validaHora(PedidosAgendamento[0].pagMinFim) }} <button class="btn btn-info" @click.prevent="dialogEntregas = true">Alterar</button> </p>
                                                            <p v-if="PedidosCli.pedOrigemPedido == 'Manual'" class="texto-mensagem mb-0" style="color:#333333;">
                                                                <strong>Link Pagamento:</strong> {{ PedidosCli.pedCodigoRetornoPgto }} </p>
                                                        </div>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="12">
                                                    <v-card style="padding: 25px;" color="#f9f9f9">
                                                        <div>
                                                            <h5 class="subtitulo" style="padding-bottom: 15px;">itens do
                                                                Pedido</h5>
                                                            <div v-for="produto in ListaPedidoItem" :key="produto.pdiId"
                                                                class="descricao-pedido border-top py-3">
                                                                <div class="row">
                                                                    <div
                                                                        class="col-3 col-md-2 d-flex align-items-center justify-content-center">
                                                                        <div class="descricao-img">
                                                                            <img v-if="produto.proProdutos.proCampoExtra10 != ''"
                                                                                class="img-fluid border border-rounded mb-3 mb-md-0"
                                                                                :src="$imgURL + produto.proProdutos.proCampoExtra10"
                                                                                alt="">
                                                                            <img v-else
                                                                                class="img-fluid border border-rounded mb-3 mb-md-0"
                                                                                :src="$imgURL + '/imagens/indisponivel.jpg'"
                                                                                alt="">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6 col-md-5 d-flex align-items-center">
                                                                        <div class="descricao-content">
                                                                            <h6 class="subtitulo">{{ produto.pdiNomeProduto
                                                                            }}</h6>
                                                                            <p class="texto-mensagem"> Código: {{
                                                                                produto.proId }}</p>
                                                                            <p class="texto-mensagem"> Referência: {{
                                                                                produto.proProdutos.proReferencia }} </p>
                                                                            <p v-if="produto.pdiCampoExtra1 !='' && produto.pdiCampoExtra1 != null" class="texto-mensagem"> Texto: {{
                                                                                produto.pdiCampoExtra1 }} </p>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6 col-md-1 d-flex align-items-center">
                                                                        <div class="descricao-content">
                                                                            <h6 class="subtitulo">{{ produto.pdiQtdeProduto
                                                                            }}</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6 col-md-2 d-flex align-items-center">
                                                                        <div class="descricao-content">
                                                                            <h6 class="subtitulo" style="font-size: 15px;">
                                                                                R$ {{
                                                                                    parseFloat(produto.pdiValorProduto).toFixed(2).toString().replace('.', ',')
                                                                                }}</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6 col-md-2 d-flex align-items-center">
                                                                        <div class="descricao-content">
                                                                            <h6 class="subtitulo" style="font-size: 15px;">
                                                                                R$ {{
                                                                                    parseFloat(produto.pdiValorTotalProduto).toFixed(2).toString().replace('.', ',')
                                                                                }}</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-for="adicional in ListaPedidoItemAdicional"
                                                                :key="adicional.piaId"
                                                                class="descricao-pedido border-top py-3">
                                                                <div class="row">
                                                                    <div
                                                                        class="col-3 col-md-2 d-flex align-items-center justify-content-center">
                                                                        <div class="descricao-img">
                                                                            <img v-if="adicional.proProdutosAvulsos.proProdutosAvulsosImagens != null"
                                                                                class="img-fluid border border-rounded mb-3 mb-md-0"
                                                                                style="width:80%;"
                                                                                :src="$imgURL + adicional.proProdutosAvulsos.proProdutosAvulsosImagens[0].paiUrlImagem"
                                                                                alt="">
                                                                            <img v-else
                                                                                class="img-fluid border border-rounded mb-3 mb-md-0"
                                                                                style="width:70%;"
                                                                                :src="$imgURL + '/imagens/indisponivel.jpg'"
                                                                                alt="">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6 col-md-5 d-flex align-items-center">
                                                                        <div class="descricao-content">
                                                                            <h6 class="subtitulo">{{
                                                                                adicional.piaNomeProduto }}</h6>
                                                                            <p class="texto-mensagem"> Código: {{
                                                                                adicional.proProdutosAvulsos.praReferencia }}
                                                                            </p>
                                                                            <small v-if="adicional.piaCampoExtra1!=''" class="texto-mensagem" style="font-size: 12px;"> <strong>Texto:</strong> {{
                                                                                adicional.piaCampoExtra1 }}
                                                                            </small>
                                                                            <div style="display: inline-flex;">
                                                                                <small v-if="adicional.piaCampoExtra2!=''" class="texto-mensagem" style="font-size: 12px;"> <strong>Arte:</strong> 
                                                                                    <a :href="$imgURL + adicional.piaCampoExtra2" download target="_blank">
                                                                                      <img :src='$imgURL + adicional.piaCampoExtra2' style="width: 80px;" />
                                                                                    </a>
                                                                                </small>
                                                                                <small v-if="adicional.piaCampoExtra3!=''" class="texto-mensagem" style="font-size: 12px;"> <strong>Imagem:</strong> 
                                                                                    <a :href="$imgURL + adicional.piaCampoExtra3" download target="_blank">
                                                                                      <img :src='$imgURL + adicional.piaCampoExtra3' style="width: 50px;" />
                                                                                    </a>
                                                                                </small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6 col-md-1 d-flex align-items-center">
                                                                        <div class="descricao-content">
                                                                            <h6 class="subtitulo">{{
                                                                                adicional.piaQtdeProduto }}</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6 col-md-2 d-flex align-items-center">
                                                                        <div class="descricao-content">
                                                                            <h6 class="subtitulo" style="font-size: 15px;">
                                                                                R$ {{
                                                                                    parseFloat(adicional.piaValorProduto).toFixed(2).toString().replace('.', ',')
                                                                                }}</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6 col-md-2 d-flex align-items-center">
                                                                        <div class="descricao-content">
                                                                            <h6 class="subtitulo" style="font-size: 15px;">
                                                                                R$ {{
                                                                                    parseFloat(adicional.piaValorTotalProduto).toFixed(2).toString().replace('.', ',')
                                                                                }}</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="12">
                                                    <v-card style="padding: 25px;" color="#f7f7f7">
                                                        <div>
                                                            <div class="d-flex align-items-center justify-content-between">
                                                                <h5 class="subtitulo">Cupom Desconto</h5>
                                                                <h6 class="subtitulo">(-) R$ {{
                                                                    parseFloat(PedidosCli.pedValorCupom).toFixed(2).toString().replace('.', ',')
                                                                }}</h6>
                                                            </div>
                                                            <div class="d-flex align-items-center justify-content-between">
                                                                <h5 class="subtitulo">Frete</h5>
                                                                <h6 class="subtitulo">R$ {{
                                                                    parseFloat(PedidosCli.pedValorFrete).toFixed(2).toString().replace('.', ',')
                                                                }}</h6>
                                                            </div>
                                                            <div class="d-flex align-items-center justify-content-between">
                                                                <h5 class="subtitulo">Total</h5>
                                                                <h6 class="subtitulo">R$ {{
                                                                    parseFloat(TotalPedido).toFixed(2).toString().replace('.', ',')
                                                                }}</h6>
                                                            </div>
                                                        </div>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-window-item>
                        <v-window-item value="impressao">
                            <div ref="tabela" class="sua-tabela-classe">
                                <v-table style="padding-top: 20px;font-size: 18px;"
                                    v-if="PedidosCli != '' && Cidades != '' && Estados != '' && Bairros != '' && PedidosMensagem.length > 0">
                                    <tr>
                                        <td style="width: 70%;vertical-align: top;border: none;">
                                <v-table>
                                    <tr>
                                        <td colspan="2"><strong>Pedido:</strong> {{ PedidosCli.pedId }}</td>
                                        <td style="text-align: center;"><strong>Data</strong></td>
                                        <td> {{ formattedDate(PedidosCli.pedDataPedido) }}</td>
                                        <td><strong>Status Pagamento</strong></td>
                                        <td>{{ PedidosCli.pedSituacaoPedido }}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Cliente</strong></td>
                                        <td colspan="3"> {{ Cliente.cliNomeEmpresa }}</td>
                                        <td><strong>Whatsapp</strong></td>
                                        <td>{{ Cliente.cliWhatsapp }}</td>
                                    </tr>
                                    <tr style="font-weight: 800;text-align: center;background-color: #f7f7f7;">
                                        <td>Qtde</td>
                                        <td colspan="4">Produto</td>
                                        <td>Valor</td>
                                    </tr>
                                    <tr v-for="produto in ListaPedidoItem" :key="produto.pdiId">
                                        <td style="text-align: center;"> {{ produto.pdiQtdeProduto }}</td>
                                        <td colspan="4">
                                            <h6 class="subtitulo">{{ produto.pdiNomeProduto }}</h6>
                                        </td>
                                        <td>R$ {{
                                            parseFloat(produto.pdiValorTotalProduto).toFixed(2).toString().replace('.', ',') }}
                                        </td>
                                    </tr>
                                    <tr v-for="adicional in ListaPedidoItemAdicional" :key="adicional.piaId">
                                        <td style="text-align: center;"> {{ adicional.piaQtdeProduto }}</td>
                                        <td colspan="4">
                                            <h6 class="subtitulo">{{ adicional.piaNomeProduto }}</h6>
                                            <small style="font-size:11px;">{{adicional.piaCampoExtra1 }}</small>
                                        </td>
                                        <td>R$ {{
                                            parseFloat(adicional.piaValorTotalProduto).toFixed(2).toString().replace('.', ',') }}
                                        </td>
                                    </tr>
                                    <tr style="height: 30px;">
                                        <td></td>
                                        <td colspan="4">
                                            <h6 class="subtitulo"></h6>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                    <tr style="font-weight: 800;text-align: center;background-color: #f7f7f7;">
                                        <td colspan="2">Forma Pagamento</td>
                                        <td>Desconto</td>
                                        <td>Frete</td>
                                        <td colspan="2">Valor total</td>
                                    </tr>
                                    <tr style="text-align: center;">
                                        <td colspan="2">
                                            <div class="pedido_dados" v-if="PedidosCli.pedFormaPgto == 'credit_card'">Cartão
                                                Crédito</div>
                                            <div class="pedido_dados" v-else-if="PedidosCli.pedFormaPgto == 'bank_transfer'">PIX
                                            </div>
                                            <div class="pedido_dados" v-else>{{ PedidosCli.pedFormaPgto }}</div>
                                        </td>
                                        <td>
                                            R$ {{ parseFloat(PedidosCli.pedValorCupom).toFixed(2).toString().replace('.', ',') }}
                                        </td>
                                        <td>
                                            R$ {{ parseFloat(PedidosCli.pedValorFrete).toFixed(2).toString().replace('.', ',') }}
                                        </td>
                                        <td colspan="2">
                                            R$ {{ parseFloat(TotalPedido).toFixed(2).toString().replace('.', ',') }}
                                        </td>
                                    </tr>
                                    <tr style="font-weight: 800;text-align: center;background-color: #f7f7f7;">
                                        <td colspan="6"> Dados de Entrega</td>
                                    </tr>
                                    <tr>
                                        <td colspan="6">
                                            <p class="texto-mensagem mb-0" style="color:#333333;"><strong>Para:
                                                </strong>{{ PedidosMensagem[0].pmsNomePara }} -
                                                {{ PedidosMensagem[0].pmsTelefoneRecebe }}</p>
                                            <p class="texto-mensagem mb-0" style="color:#333333;" v-if="PedidosEndereco != ''"><strong>Endereço:</strong> {{
                                                PedidosEndereco[0].pdcEndereco }}, {{ PedidosEndereco[0].pdcNumero }} - {{
            PedidosEndereco[0].pdcComplemento }} -  {{
                                                PedidosEndereco[0].pdcBairro == '' ? Bairros.filter(x => x.baiId ==
                                                    PedidosEndereco[0].baiId)[0].baiNome : PedidosEndereco[0].pdcBairro }} - {{
                                                Cidades.filter(x => x.cidId == PedidosEndereco[0].cidId)[0].cidNome }} / {{
            Estados.filter(x => x.cetId == PedidosEndereco[0].cetId)[0].cetSigla }} - Zona: {{  Cidades.filter(x => x.cidId == PedidosEndereco[0].cidId)[0].cidZona }} {{ PedidosEndereco[0].pdcBairro == '' ? Bairros.filter(x => x.baiId ==
                                                    PedidosEndereco[0].baiId)[0].baiZona : ''  }} </p>
                                            <p class="texto-mensagem mb-0" style="color:#333333;" v-if="PedidosAgendamento!=''"><strong>Entrega Dia:</strong>
                                                {{ formattedDate(PedidosAgendamento[0].pagDiaEntrega) }} entre {{
                                                    validaHora(PedidosAgendamento[0].pagHoraIni) }}:{{validaHora(PedidosAgendamento[0].pagMinIni) }} e {{validaHora(PedidosAgendamento[0].pagHoraFim) }}:{{ validaHora(PedidosAgendamento[0].pagMinFim) }} </p>
                                            <p class="texto-mensagem mb-0" style="color:#333333;"><strong>Cartão:</strong>{{ PedidosMensagem[0].pmsTexto.substring(0, 30) + "..." }} </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="6" style="height: 40px;">
                                            <p class="texto-mensagem mb-0" style="color:#333333;"><strong>Obs Cliente</strong>
                                                {{ PedidosCli.pedObsCliente }}</p>
                                        </td>
                                    </tr>
                                </v-table>
                                </td>
                                <td style="width: 70%;vertical-align: top;border: none;height: 100%;">
                                    <v-table>
                                        <tr style="font-weight: 800;text-align: center;background-color: #f7f7f7;">
                                            <td colspan="2">Comanda Entrega</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Pedido:</strong></td>
                                            <td>{{ PedidosCli.pedId }}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <p class="texto-mensagem mb-0" style="color:#333333;"><strong>Para:
                                                    </strong>{{ PedidosMensagem[0].pmsNomePara }} -
                                                    {{ PedidosMensagem[0].pmsTelefoneRecebe }}</p>
                                                <p class="texto-mensagem mb-0" style="color:#333333;" v-if="PedidosEndereco != ''"><strong>Endereço:</strong>
                                                    {{ PedidosEndereco[0].pdcEndereco }}, {{ PedidosEndereco[0].pdcNumero }} -
                                                    {{ PedidosEndereco[0].pdcComplemento }} - {{ PedidosEndereco[0].pdcBairro == '' ? Bairros.filter(x => x.baiId ==
                                                        PedidosEndereco[0].baiId)[0].baiNome : PedidosEndereco[0].pdcBairro }} -  {{ Cidades.filter(x => x.cidId ==
                                                        PedidosEndereco[0].cidId)[0].cidNome }} / {{ Estados.filter(x => x.cetId ==
            PedidosEndereco[0].cetId)[0].cetSigla }} - Zona: {{  Cidades.filter(x => x.cidId == PedidosEndereco[0].cidId)[0].cidZona }} {{ PedidosEndereco[0].pdcBairro == '' ? Bairros.filter(x => x.baiId ==
                                                    PedidosEndereco[0].baiId)[0].baiZona : ''  }} </p>
                                              
                                                <p class="texto-mensagem mb-0" style="color:#333333;"><strong>Entrega
                                                        Dia:</strong> {{ formattedDate(PedidosAgendamento[0].pagDiaEntrega) }}
                                                    entre {{ validaHora(PedidosAgendamento[0].pagHoraIni) }}:{{
                                                        validaHora(PedidosAgendamento[0].pagMinIni) }} e {{
            validaHora(PedidosAgendamento[0].pagHoraFim) }}:{{
            validaHora(PedidosAgendamento[0].pagMinFim) }} </p>
                                            </td>
                                        </tr>
                                        <tr style="font-weight: 800;text-align: center;background-color: #f7f7f7;">
                                            <td colspan="2" v-if="PedidosCli.pedFormaPgto == 'credit_card'" >Documento / Assinatura Recebe</td>
                                            <td colspan="2" v-else>Assinatura Recebe</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" style="height: 160px;"></td>
                                        </tr>
                                    </v-table>
                                </td>
                                </tr>
                                </v-table>
                            </div>
                            <center><button @click="gerarPDF" class="btn btn-success" style="margin-top: 30px;">Gerar PDF</button></center>
                        </v-window-item>
                        <v-window-item value="impressao2">
                            <div ref="tabela" class="sua-tabela-classe">
                                <v-table style="padding: 20px;font-size: 18px;" v-if="PedidosCli != '' && PedidosAgendamento != '' && Cidades != '' && Estados != '' && Bairros != '' && PedidosMensagem.length > 0">
                                    <tr>
                                        <td colspan="2"><strong>PEDIDO:</strong> {{ PedidosCli.pedId }}</td>
                                        <td style="text-align: center;"><strong>DATA</strong></td>
                                        <td> {{ formattedDate(PedidosCli.pedDataPedido) }}</td>
                                        <td> <strong>ENTREGA DIA:</strong> {{ formattedDate(PedidosAgendamento[0].pagDiaEntrega) }} entre {{ validaHora(PedidosAgendamento[0].pagHoraIni) }}:{{
                                                                            validaHora(PedidosAgendamento[0].pagMinIni) }} e {{ validaHora(PedidosAgendamento[0].pagHoraFim) }}:{{validaHora(PedidosAgendamento[0].pagMinFim) }}
                                        </td>
                                        <td><strong>STATUS</strong></td>
                                    </tr>
                                    <tr>
                                        <td><strong>CLIENTE</strong></td>
                                        <td colspan="4"> {{ Cliente.cliNomeEmpresa }}</td>
                                        <td style="text-transform: uppercase;">{{ PedidosCli.pedSituacaoPedido }}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>WHATSAPP</strong></td>
                                        <td colspan="5">{{ Cliente.cliWhatsapp }}</td>
                                    </tr>
                                    <tr style="font-weight: 800;text-align: center;background-color: #f7f7f7;">
                                        <td>QUANTIDADE</td>
                                        <td colspan="4">PRODUTO</td>
                                        <td>VALOR</td>
                                    </tr>
                                    <tr v-for="produto in ListaPedidoItem" :key="produto.pdiId">
                                        <td style="text-align: center;"> {{ produto.pdiQtdeProduto }}</td>
                                        <td colspan="4">
                                            <h6 class="subtitulo">{{ produto.pdiNomeProduto }}</h6>
                                        </td>
                                        <td>R$ {{
                                            parseFloat(produto.pdiValorTotalProduto).toFixed(2).toString().replace('.', ',') }}
                                        </td>
                                    </tr>
                                    <tr v-for="adicional in ListaPedidoItemAdicional" :key="adicional.piaId">
                                        <td style="text-align: center;"> {{ adicional.piaQtdeProduto }}</td>
                                        <td colspan="4">
                                            <h6 class="subtitulo">{{ adicional.piaNomeProduto }}</h6>
                                            <small style="font-size:11px;">{{adicional.piaCampoExtra1 }}</small>
                                        </td>
                                        <td>R$ {{
                                            parseFloat(adicional.piaValorTotalProduto).toFixed(2).toString().replace('.', ',') }}
                                        </td>
                                    </tr>
                                    <tr style="height: 30px;">
                                        <td></td>
                                        <td colspan="4">
                                            <h6 class="subtitulo"></h6>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                    <tr style="font-weight: 800;text-align: center;background-color: #f7f7f7;">
                                        <td colspan="2">FORMA PAGT</td>
                                        <td>DESCONTO</td>
                                        <td>FRETE</td>
                                        <td colspan="2">VALOR TOTAL</td>
                                    </tr>
                                    <tr style="text-align: center;">
                                        <td colspan="2">
                                            <div class="pedido_dados" v-if="PedidosCli.pedFormaPgto == 'credit_card'">Cartão
                                                Crédito</div>
                                            <div class="pedido_dados" v-else-if="PedidosCli.pedFormaPgto == 'bank_transfer'">PIX
                                            </div>
                                            <div class="pedido_dados" v-else>{{ PedidosCli.pedFormaPgto }}</div>
                                        </td>
                                        <td>
                                            R$ {{ parseFloat(PedidosCli.pedValorCupom).toFixed(2).toString().replace('.', ',') }}
                                        </td>
                                        <td>
                                            R$ {{ parseFloat(PedidosCli.pedValorFrete).toFixed(2).toString().replace('.', ',') }}
                                        </td>
                                        <td colspan="2">
                                            R$ {{ parseFloat(TotalPedido).toFixed(2).toString().replace('.', ',') }}
                                        </td>
                                    </tr>
                                    <tr style="font-weight: 800;text-align: center;background-color: #f7f7f7;">
                                        <td colspan="6"> DADOS DA ENTREGA</td>
                                    </tr>
                                    <tr>
                                        <td colspan="6">
                                            <p class="texto-mensagem mb-0" style="color:#333333;"><strong>Para:
                                                </strong>{{ PedidosMensagem[0].pmsNomePara }} -
                                                {{ PedidosMensagem[0].pmsTelefoneRecebe }}</p>
                                            <p class="texto-mensagem mb-0" style="color:#333333;" v-if="PedidosEndereco != ''"><strong>Endereço:</strong> {{
                                                PedidosEndereco[0].pdcEndereco }}, {{ PedidosEndereco[0].pdcNumero }} - {{
            PedidosEndereco[0].pdcComplemento }} -  {{
                                                PedidosEndereco[0].pdcBairro == '' ? Bairros.filter(x => x.baiId ==
                                                    PedidosEndereco[0].baiId)[0].baiNome : PedidosEndereco[0].pdcBairro }} - {{
                                                Cidades.filter(x => x.cidId == PedidosEndereco[0].cidId)[0].cidNome }} / {{
            Estados.filter(x => x.cetId == PedidosEndereco[0].cetId)[0].cetSigla }} - Zona: {{  Cidades.filter(x => x.cidId == PedidosEndereco[0].cidId)[0].cidZona }} {{ PedidosEndereco[0].pdcBairro == '' ? Bairros.filter(x => x.baiId ==
                                                    PedidosEndereco[0].baiId)[0].baiZona : ''  }} </p>
                                            <p class="texto-mensagem mb-0" style="color:#333333;"><strong>Entrega Dia:</strong>
                                                {{ formattedDate(PedidosAgendamento[0].pagDiaEntrega) }} entre {{
                                                    validaHora(PedidosAgendamento[0].pagHoraIni) }}:{{validaHora(PedidosAgendamento[0].pagMinIni) }} e {{validaHora(PedidosAgendamento[0].pagHoraFim) }}:{{ validaHora(PedidosAgendamento[0].pagMinFim) }} </p>
                                            <p class="texto-mensagem mb-0" style="color:#333333;"><strong>Cartão:</strong>{{ PedidosMensagem[0].pmsTexto.substring(0, 30) + "..." }} </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="6" style="height: 40px;">
                                            <p class="texto-mensagem mb-0" style="color:#333333;"><strong>Obs Cliente</strong>
                                                {{ PedidosCli.pedObsCliente }}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="6" style="height: 40px;"></td>
                                    </tr>
                                    <tr style="font-weight: 800;text-align: center;background-color: #f7f7f7;">
                                        <td colspan="6">COMANDA DE ENTREGA</td>
                                    </tr>
                                    <tr>
                                        <td><strong>PEDIDO:</strong></td>
                                        <td>{{ PedidosCli.pedId }}</td>
                                        <td><strong>ENTREGA EM:</strong></td>
                                        <td colspan="3">  {{ formattedDate(PedidosAgendamento[0].pagDiaEntrega) }} entre {{ validaHora(PedidosAgendamento[0].pagHoraIni) }}:{{
                                                                            validaHora(PedidosAgendamento[0].pagMinIni) }} e {{ validaHora(PedidosAgendamento[0].pagHoraFim) }}:{{validaHora(PedidosAgendamento[0].pagMinFim) }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="6">
                                            <p class="texto-mensagem mb-0" style="color:#333333;"><strong>Para:
                                                </strong>{{ PedidosMensagem[0].pmsNomePara }} -
                                                {{ PedidosMensagem[0].pmsTelefoneRecebe }}</p>
                                                <br>
                                            <p class="texto-mensagem mb-0" style="color:#333333;"><strong>Endereço:</strong>
                                                {{ PedidosEndereco[0].pdcEndereco }}, {{ PedidosEndereco[0].pdcNumero }} -
                                                {{ PedidosEndereco[0].pdcComplemento }} - {{ PedidosEndereco[0].pdcBairro == '' ? Bairros.filter(x => x.baiId ==
                                                    PedidosEndereco[0].baiId)[0].baiNome : PedidosEndereco[0].pdcBairro }} -  {{ Cidades.filter(x => x.cidId ==
                                                    PedidosEndereco[0].cidId)[0].cidNome }} / {{ Estados.filter(x => x.cetId ==
        PedidosEndereco[0].cetId)[0].cetSigla }} - Zona: {{  Cidades.filter(x => x.cidId == PedidosEndereco[0].cidId)[0].cidZona }} {{ PedidosEndereco[0].pdcBairro == '' ? Bairros.filter(x => x.baiId ==
                                                PedidosEndereco[0].baiId)[0].baiZona : ''  }} </p>
                                        </td>
                                    </tr>
                                    <tr style="font-weight: 800;text-align: center;background-color: #f7f7f7;">
                                        <td colspan="3" v-if="PedidosCli.pedFormaPgto == 'credit_card'" >Documento / Assinatura Recebe</td>
                                        <td colspan="3" v-else>Assinatura Recebe</td>
                                        <td colspan="3" style="height: 100px;"></td>
                                    </tr>
                                </v-table>  

                            </div>
                            <center><button @click="gerarPDF" class="btn btn-success" style="margin-top: 30px;">Gerar PDF</button></center>
                        </v-window-item>
                    </v-window>
                </div>
            </div>
        </div>
         <!--modal Agenda-->
         <v-row justify="center">
            <v-dialog v-model="dialogEntregas" class="controlcel" style="width: 600px;">
                <v-card color="#f7f7f7" style="padding: 30px;height: 300px;overflow-y: hidden;">
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="Entregas.dataEntrega" type="Date"
                                label="Data Entrega" variant="underlined" required></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-select v-model="Entregas.horaIni" label="Hora Inicial" :items="StatusHora"
                                item-title="nome" item-value="valor" variant="underlined">
                            </v-select>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-select v-model="Entregas.minIni" label="Min Inicial" :items="StatusMin"
                                item-title="nome" item-value="valor" variant="underlined">
                            </v-select>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-select v-model="Entregas.horaFim" label="Hora Final" :items="StatusHora"
                                item-title="nome" item-value="valor" variant="underlined">
                            </v-select>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-select v-model="Entregas.minFim" label="Min Final" :items="StatusMin"
                                item-title="nome" item-value="valor" variant="underlined">
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="black" variant="text" @click="dialogEntregas = false">
                            Cancelar
                        </v-btn>
                        <v-btn class="btn btn-success" @click="AlterarDataEntrega()">
                            Salvar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import moment from 'moment'
import { useRoute } from 'vue-router';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';


export default {
    name: 'ListaPedidosView',
    components: {
        EasyDataTable,
        moment
    },
    setup() {
        const route = useRoute();
        const id = route.params.idPed;

        return {
            id,
            };
    },
    data: () => ({
        tab: null,
        NomeTela: 'Pedidos',
        InfoTela: 'Detalhe Pedido',
        MostraGrid: true,
        Cliente: [],
        headers: [],
        Idiomas: [],
        vshowidioma: true,
        CadastroTelas: [{ cdtId: 28, cdtNome: "Detelhe Pedido" }],
        PedidosCli: [],
        Pedidos: [],
        TotalPedido: 0,
        validacomprova: [],
        PedidosComprova: [],
        showModal_comprova: false,
        ListaPedidoItem: [],
        ListaPedidoItemAdicional: [],
        PedidosMensagem: [],
        PedidosAgendamento: [],
        PedidosEndereco: [],
        Estados: [],
        Cidades: [],
        Bairros: [],
        selectedFile: '',
        urlComprovante: '',
        PedidoComprovante: [],
        Situacoes: [],
        //entregas
        Entregas : {
            dataEntrega: '',
            horaIni:'',
            minIni:'',
            horaFim:'',
            minFim:'',
        },
        StatusHora: [
            { nome: "0", valor: 0 },
            { nome: "1", valor: 1 },
            { nome: "2", valor: 2 },
            { nome: "3", valor: 3 },
            { nome: "4", valor: 4 },
            { nome: "5", valor: 5 },
            { nome: "6", valor: 6 },
            { nome: "7", valor: 7 },
            { nome: "8", valor: 8 },
            { nome: "9", valor: 9 },
            { nome: "10", valor: 10 },
            { nome: "11", valor: 11 },
            { nome: "12", valor: 12 },
            { nome: "13", valor: 13 },
            { nome: "14", valor: 14 },
            { nome: "15", valor: 15 },
            { nome: "16", valor: 16 },
            { nome: "17", valor: 17 },
            { nome: "18", valor: 18 },
            { nome: "19", valor: 19 },
            { nome: "20", valor: 20 },
            { nome: "21", valor: 21 },
            { nome: "22", valor: 22 },
            { nome: "23", valor: 23 },
        ],
        StatusMin: [
            { nome: "00", valor: 0 },
            { nome: "15", valor: 15 },
            { nome: "30", valor: 30 },
            { nome: "45", valor: 45 },
        ],
        dialogEntregas: false,
        PedidosAgendamentoAtual:[],
    }),
    methods: {
        async gerarPDF() {
            await this.$nextTick();
            const pdf = new jsPDF({
                orientation: 'portrait', // Retrato (p)
                unit: 'mm', // Unidade de medida
                format: 'a4', // Formato de página A4
            });

            const tabela = this.$refs.tabela; // Substitua 'tabela' pelo ref correto da sua tabela

            // Capture a tabela como uma imagem
            const canvas = await html2canvas(tabela);

            // Calcule a largura e altura da página A4 em mm
            const larguraA4 = 210;
            const alturaA4 = 297;

            // Calcule a escala para ajustar o conteúdo à página A4
            const escala = Math.min(larguraA4 / canvas.width, alturaA4 / canvas.height);

            // Defina as dimensões da imagem da tabela com base na escala
            const larguraImagem = canvas.width * escala;
            const alturaImagem = canvas.height * escala;

            // Adicione a imagem ao PDF e ajuste as dimensões
            pdf.addImage(canvas, 'PNG', 0, 0, larguraImagem, alturaImagem);


             // Imprima o PDF diretamente
             //pdf.autoPrint();

             //pdf.autoPrint({ variant: 'non-conform' });
             window.open(pdf.output('bloburl'), '_blank');
            // Salve ou exiba o PDF
            //pdf.save(`Pedido_${this.PedidosCli.pedId}.pdf`);
           
            this.PutImpressao('Sim');
            // Configuração para imprimir sem diálogo de impressão
            //pdf.output('dataurlnewwindow');
            
            },
        onReady(editor) {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        formattedDate(value) {
            if (value != null || value != "") {
                return this.$moment(value).format('DD/MM/YYYY')
            }
            else {
                return "";
            }

        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        validaHora(value) {
            var _hora = value.toString();
            if (_hora.length == 1)
                _hora = "0" + _hora;
            return _hora;
        },
        calculatotal() {
            var valorAvulsos = 0;
            var valorProdutos = 0;


            if (this.ListaPedidoItem != '') {
                this.ListaPedidoItem.forEach(item => {
                    valorProdutos += item.pdiValorTotalProduto;
                });
            }

            if (this.ListaPedidoItemAdicional != '') {
                this.ListaPedidoItemAdicional.forEach(item => {
                    valorAvulsos += item.piaValorTotalProduto;
                });
            }

            this.TotalPedido = (valorProdutos + valorAvulsos + parseFloat(this.PedidosCli.pedValorFrete)) - parseFloat(this.PedidosCli.pedValorCupom);
        },
        async PutSituacao() {
            var _situacao = await this.Situacoes.filter(x => x.pspId == this.PedidosCli.pspId)[0].pspSituacao;
            axios.put(`/Process/PedPedidos/UpdateSituacao/` + this.PedidosCli.pedId + '/' + this.PedidosCli.pspId + '/' + _situacao)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Alterado.")
                        this.EnviaAviso(_situacao);
                    }
                });
            //enviar email
        },
        async PutImpressao(value) {
            axios.put(`/Process/PedPedidos/UpdateImpressao/` + this.PedidosCli.pedId + '/' + value)
                .then(response => {
                    if (response.status == 200) {
                        console.log("impresso")
                    }
                });
            //enviar email
        },
        EnviaAviso(_situacao) {
            axios.get(`/Process/CadMensagensPadrao/GetAll`)
                .then(response => {
                    this.templateEmail = response.data.filter(x => x.mpdId == 3);

                    let _dadosEmail = {
                        nomeDe: 'Cestas Porto Alegre',
                        nomePara: this.Cliente.cliNomeEmpresa,
                        assunto: 'Andamento Pedido: ' + this.PedidosCli.pedId,
                        destinatario: this.Cliente.cliEmail,
                        emailResposta: 'contato@cestasportoalegre.com',
                        textoEmail: this.templateEmail[0].mpdTexto.replace('[NomeCliente]', this.Cliente.cliNomeEmpresa).replace('[NumeroPedido]', this.PedidosCli.pedId).replace('[Status]', _situacao),
                        nomeAnexo: '',
                        anexo: '',
                        tipoAnexo: ''
                    }
                    axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
                        .then(response => {
                            if (response.data == 1) {
                                console.log('email enviado')
                            }
                        });
                });
        },
        async AlterarDataEntrega(){
          await axios.get('/Process/PedPedidoAgendamento/GetAllByPedId/' + this.id)
                .then(response => {
                    this.PedidosAgendamentoAtual = response.data;
                    console.log('PedidosAgendamentoAtual')
                    console.log(this.PedidosAgendamentoAtual)

                    let _pedidoAgendamento = {
                            PagId: this.PedidosAgendamentoAtual[0].pagId,
                            GrtId: 7,
                            CdtId: 28,
                            IdiId: 1,
                            PagDiaEntrega: moment(this.Entregas.dataEntrega).format("YYYY-MM-DD"),
                            PagHoraIni: this.Entregas.horaIni,
                            PagMinIni: this.Entregas.minIni,
                            PagHoraFim: this.Entregas.horaFim,
                            PagMinFim: this.Entregas.minFim,
                            PagUrlImagemEntrega:'',
                            PedId: parseInt(this.id),
                            }

                            console.log('_pedidoAgendamento')
                            console.log(_pedidoAgendamento)

                           axios.put(`/Process/PedPedidoAgendamento/Update`, _pedidoAgendamento)
                                    .then(response => {
                                        if (response.data > 0) {   
                                                console.log('_pedidoAgendamento alterado')              
                                }
                            });
                            this.Entregas.dataEntrega = '';
                            this.Entregas.horaIni = '';
                            this.Entregas.minIni = '';
                            this.Entregas.horaFim = '';
                            this.Entregas.minFim = '';
                            this.dialogEntregas = false;
                            this.Get();
                })
                .catch(error => {
                    console.error(error);
                });
        },
        async Get() {
            axios.get(`/Process/CadIdiomas/GetAll`)
                .then(response => {
                    this.Idiomas = response.data.filter(x => x.idiAtivo);
                    if (this.Idiomas.length == 1) {
                        this.Pedidos.idiId = this.Idiomas[0].idiId;
                        this.vshowidioma = false;
                    }
                    axios.get(`/Process/PedPedidos/GetById/` + this.id)
                        .then(response => {
                            this.PedidosCli = response.data;
                            console.log('this.PedidosCli')
                           // console.log(this.PedidosCli)

                            axios.get(`/Process/CliClientes/GetById/` + this.PedidosCli.cliId)
                                .then(response => {
                                    this.Cliente = response.data;
                                    console.log('this.Cliente')
                                   // console.log(this.Cliente)
                                });

                        });
                });

            await axios.get('/Process/PedPedidoItem/GetAllByPedId/' + this.id)
                .then(response => {
                    this.ListaPedidoItem = response.data;
                    console.log('ListaPedidoItem')
                    console.log(this.ListaPedidoItem)
                })
                .catch(error => {
                    console.error(error);
                });

            await axios.get('/Process/PedPedidoItemAdicional/GetAllByPedId/' + this.id)
                .then(response => {
                    this.ListaPedidoItemAdicional = response.data;
                    console.log('ListaPedidoItemAdicional')
                   // console.log(this.ListaPedidoItemAdicional)
                })
                .catch(error => {
                    console.error(error);
                });

            axios.get('/Process/PedPedidoMensagem/GetAllByPedId/' + this.id)
                .then(response => {
                    this.PedidosMensagem = response.data;
                    console.log('PedidosMensagem')
                   // console.log(this.PedidosMensagem)
                })
                .catch(error => {
                    console.error(error);
                });

            axios.get('/Process/PedPedidoAgendamento/GetAllByPedId/' + this.id)
                .then(response => {
                    this.PedidosAgendamento = response.data;
                    console.log('PedidosAgendamento')
                   // console.log(this.PedidosAgendamento)
                })
                .catch(error => {
                    console.error(error);
                });

            axios.get('/Process/PedPedidoEndereco/GetAllByPedId/' + this.id)
                .then(response => {
                    this.PedidosEndereco = response.data;
                    console.log('PedidosEndereco')
                   // console.log(this.PedidosEndereco)
                })
                .catch(error => {
                    console.error(error);
                });

            axios.get(`/Process/CadEstados/GetByPaisId/76`)
                .then(response => {
                    this.Estados = response.data
                    console.log('this.Estados')
                    //console.log(this.Estados)
                });

            axios.get(`/Process/CadCidades/GetAll`)
                .then(response => {
                    this.Cidades = response.data
                    console.log('this.Cidades')
                    //console.log(this.Cidades)
                });

            axios.get(`/Process/CadBairros/GetAll`)
                .then(response => {
                    this.Bairros = response.data
                    console.log('this.Bairros')
                    //console.log(this.Bairros)
                });

            axios.get(`/Process/PedCadSituacaoPedido/GetAll`)
                .then(response => {
                    this.Situacoes = response.data
                    console.log('this.Situacoes')
                    //console.log(this.Situacoes)
                });

            axios.get('/Process/PedPedidoPgtoComprova/GetAllByPedId/' + this.id)
                .then(response => {
                    this.PedidoComprovante = response.data.filter(x => x.ppcUrlImagem != '')
                    console.log('this.PedidoComprovante')
                    //console.log(this.PedidoComprovante)
                });

            this.calculatotal();
        }
    },
    created() {
        this.Get();
    },
}
</script>

<style scoped>.tabinfo {
    font-size: 14px;
    text-transform: capitalize;
}


td {
    border: 1px solid #333333;
    padding: 3px;
}

.sua-tabela-classe table tr td {
    font-size: 14px;
}

</style>
