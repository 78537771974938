<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left" style="display: inline-flex;text-decoration: underline;">
                                <h4 class="text-black h4" style="padding-right: 5px;">Tela: {{ NomeTela }} </h4>
                            </div>
                        </div>
                    </div>
                    <v-tabs v-model="tab" color="deep-purple-accent-4" bg-color="#f7f7f7">
                        <v-tab value="dados" class="tabinfo">Cadastro Pedido</v-tab>
                    </v-tabs>
                    <v-window v-model="tab">
                        <v-window-item value="dados">
                            <v-form>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <v-card color="#f7f7f7" style="padding: 15px;">
                                                <v-row>
                                                    <v-col cols="12">
                                                        <v-row>
                                                            <v-col cols="12" md="4" v-show="false">
                                                                <v-text-field v-model="Clientes.cliId" label="Id" variant="underlined"></v-text-field>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col cols="12" md="8">
                                                                <v-text-field v-model="Clientes.cliEmail" label="E-mail" 
                                                                    variant="underlined"></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" md="4" class="d-flex align-center">
                                                                <v-btn class="btn btn-info" @click="PesquisaCliente()">Pesquisar</v-btn>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-text-field v-model="Clientes.cliNomeEmpresa" :disabled="vshow"
                                                            label="Nome" variant="underlined" required></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-text-field v-model="Clientes.cliWhatsapp" :disabled="vshow"
                                                                ref="whatsAppInput2"
                                                                    label="Whatsapp" variant="underlined" required></v-text-field>
                                                                    <span class="small-text">*Somente números com DDD</span>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                            <v-card color="#f7f7f7" style="padding: 15px;margin-top: 20px;" >
                                                <v-row>
                                                    <v-col cols="12" md="12">
                                                        <v-checkbox v-model="retirarNaLoja" label="Retirar na loja" @change="preencherEnderecoFixo"></v-checkbox>
                                                    </v-col>
                                                    <v-col cols="12" md="6" v-if="Estados.length > 1">
                                                        <v-select v-model="selectedEstados" label="Estados" :items="Estados" @update:modelValue="CarregaCidades(selectedEstados)"
                                                            item-title="cetNome" item-value="cetId" variant="underlined">
                                                        </v-select>
                                                    </v-col>
                                                    <v-col cols="12" md="6">
                                                        <v-select v-model="selectedCidades" :disabled="retirarNaLoja" label="Cidade" :items="Cidades" @update:modelValue="CarregaBairros(selectedCidades)"
                                                            item-title="cidNome" item-value="cidId" variant="underlined">
                                                        </v-select>
                                                    </v-col>
                                                    <v-col cols="12" md="6" v-if="Bairros!='' && Bairros!=null">
                                                        <v-select v-model="selectedBairros" :disabled="retirarNaLoja" label="Bairro" :items="Bairros" @update:modelValue="AtualizaData(2)"
                                                            item-title="baiNome" item-value="baiId" variant="underlined">
                                                        </v-select>
                                                    </v-col>
                                                    <v-col cols="12" md="6" v-else>
                                                        <v-text-field v-model="BairroTexto" maxlength="100"
                                                            label="Bairro" variant="underlined" required></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="8"> 
                                                        <v-text-field v-model="Endereco" :disabled="retirarNaLoja" maxlength="200"
                                                            label="Endereço" variant="underlined" required></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="4">
                                                        <v-text-field v-model="Numero" type="number" :disabled="retirarNaLoja"
                                                            label="Número" variant="underlined" required></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="6">
                                                        <v-text-field v-model="Complemento" :disabled="retirarNaLoja" maxlength="200"
                                                            label="Complemento" variant="underlined" required></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="6">
                                                        <v-text-field v-model="NomePara2" maxlength="100" autocomplete="off"
                                                                    label="Recebe a entrega" variant="underlined" required>
                                                        </v-text-field>                                                   
                                                    </v-col>
                                                    <v-col cols="12" md="4">
                                                    <v-select v-model="selectedPais" label="País (DDI)" :items="Paises" @update:modelValue="TelefoneRecebe=''"
                                                        item-title="cpaNome" item-value="cpaId" variant="underlined">
                                                    </v-select>
                                                </v-col>
                                                <v-col cols="12" md="8">
                                                    <v-text-field v-model="TelefoneRecebe" autocomplete="off"
                                                     ref="whatsAppInput"
                                                        label="Telefone Recebe"  variant="underlined" required>
                                                        </v-text-field>
                                                        <span class="small-text">*Número com DDD (ex: 5199999999)</span>
                                                </v-col>
                                                    <v-col cols="12" md="12">
                                                        <v-text-field v-model="Observacao" maxlength="200"
                                                            label="Observações" variant="underlined" required></v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                            <v-card color="#f7f7f7" style="padding: 15px;margin-top: 20px;" >
                                                <v-row>
                                                    <div class="input-group mb-3" v-if="!SemCartao && !EnviarCartaoBranco">
                                                        <input v-model="Formulario.NomeDe" type="text" class="form-control form-control-icone" maxlength="100"
                                                        placeholder="De" onfocus="this.placeholder=''" onblur="this.placeholder='De'">
                                                    </div>
                                                    <div class="input-group mb-3">
                                                        <input v-model="Formulario.NomePara" type="text" class="form-control form-control-icone" maxlength="100"
                                                        placeholder="Para" onfocus="this.placeholder=''" onblur="this.placeholder='Para'">
                                                    </div>
                                                    <div class="input-group mb-3" v-if="!SemCartao && !EnviarCartaoBranco">
                                                        <textarea v-model="Formulario.Mensagem" type="text" class="form-control form-control-icone" rows="6" cols="12"
                                                        placeholder="Mensagem (*Não esqueça de assinar)" onfocus="this.placeholder=''" onblur="this.placeholder='Mensagem (*Não esqueça de assinar)'" maxlength="800"
                                                        @focus="limparPlaceholder" @blur="restaurarPlaceholder" @input="limitarCaracteres"></textarea>
                                                    </div>
                                                    <div class="input-group mb-3" v-if="!SemCartao && !EnviarCartaoBranco">
                                                        <br><small>{{ contadorCaracteres }}/800 caracteres</small>
                                                    </div>
                                                    <div class="input-group mb-3" v-if="!SemCartao && !EnviarCartaoBranco && mensagens!='' && mensagens!=null">
                                                        <br><button class="btn btn-info" @click.prevent="dialogMensagens = true">Ver sugestões de mensagens</button>
                                                    </div>
                                                    <v-col cols="12" md="12" style="display: inline-flex;">
                                                        <v-checkbox v-model="EnviarCartaoBranco" label="Enviar Cartão em Branco" @change="preencherEnviarCartaoBranco"></v-checkbox>
                                                        <v-checkbox v-model="SemCartao" label="Não Desejo Cartão" @change="preencherSemCartao"></v-checkbox>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                              <v-card color="#f7f7f7" style="padding: 15px;">
                                                <v-row>
                                                    <v-col cols="12">
                                                        <v-text-field v-model="Entregas.dataEntrega" type="Date"
                                                            label="Data Entrega" variant="underlined" required></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="3">
                                                        <v-select v-model="Entregas.horaIni" label="Hora Inicial" :items="StatusHora"
                                                            item-title="nome" item-value="valor" variant="underlined">
                                                        </v-select>
                                                    </v-col>
                                                    <v-col cols="12" md="3">
                                                        <v-select v-model="Entregas.minIni" label="Min Inicial" :items="StatusMin"
                                                            item-title="nome" item-value="valor" variant="underlined">
                                                        </v-select>
                                                    </v-col>
                                                    <v-col cols="12" md="3">
                                                        <v-select v-model="Entregas.horaFim" label="Hora Final" :items="StatusHora"
                                                            item-title="nome" item-value="valor" variant="underlined">
                                                        </v-select>
                                                    </v-col>
                                                    <v-col cols="12" md="3">
                                                        <v-select v-model="Entregas.minFim" label="Min Final" :items="StatusMin"
                                                            item-title="nome" item-value="valor" variant="underlined">
                                                        </v-select>
                                                    </v-col>
                                                </v-row>
                                              </v-card>
                                              <v-card color="#f7f7f7" style="padding: 15px;margin-top: 20px;" >
                                                  <v-row>
                                                    <v-col cols="12" md="12">
                                                        <v-combobox v-model="selectedItemProduto" label="Selecione um produto" :items="ListProdutosGeral"
                                                            searchable
                                                            clearable
                                                            auto-select-first="exact"
                                                            hide-selected
                                                            item-title="nomeConcatenado" item-value="proId" variant="underlined">
                                                        </v-combobox>
                                                    </v-col>
                                                    <v-col cols="12" md="4">
                                                        <v-text-field v-model="QtdeProduto" type="number" @input="pegaValorProduto()" label="Qtde" variant="underlined" required></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="5">
                                                        <v-text-field v-model="ValorProduto" type="text"
                                                                 @blur="formatValorProduto"
                                                                label="Valor" variant="underlined" required></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="3" style="padding-top: 30px;">
                                                        <button type="button" class="btn btn-success" @click="AddProduto()" style="padding: 2px !important;">
                                                            Incluir
                                                        </button>
                                                    </v-col>
                                                  </v-row>
                                                  <v-row>
                                                    <v-col cols="12" md="12">
                                                        <v-combobox v-model="selectedItemProdutoAdicional" label="Selecione um produto Adicional" :items="ListProdutosAvulsosGeral"
                                                            searchable
                                                            clearable
                                                            auto-select-first="exact"
                                                            hide-selected
                                                            item-title="nomeConcatenado" item-value="praId" variant="underlined">
                                                        </v-combobox>
                                                    </v-col>
                                                    <v-col cols="12" md="4">
                                                        <v-text-field v-model="QtdeProdutoAdicional" type="number" @input="pegaValorProdutoAdicional()"  label="Qtde" variant="underlined" required></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="5">
                                                        <v-text-field v-model="ValorProdutoAdicional" type="text"
                                                                @blur="formatValorProdutoAdicional"
                                                                label="Valor" variant="underlined" required></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="3" style="padding-top: 30px;">
                                                        <button type="button" class="btn btn-success" @click="AddProdutoAdicional()" style="padding: 2px !important;">
                                                            Incluir
                                                        </button>
                                                    </v-col>
                                                  </v-row>
                                                  <v-row>
                                                       <div style="padding-top: 20px;min-height: 350px;">
                                                        <h5 class="subtitulo" style="padding-bottom: 15px;">Itens do
                                                            Pedido</h5>
                                                        <div v-for="(produto,index) in ListaPedidoItem" :key="produto.pdiId"
                                                            class="descricao-pedido border-top py-3">
                                                            <div class="row">
                                                                <div
                                                                    class="col-3 col-md-2 d-flex align-items-center justify-content-center">
                                                                    <div class="descricao-img">
                                                                        <img v-if="produto.proProdutos.proCampoExtra10 != ''"
                                                                            class="img-fluid border border-rounded mb-3 mb-md-0"
                                                                            :src="$imgURL + produto.proProdutos.proCampoExtra10"
                                                                            alt="">
                                                                        <img v-else
                                                                            class="img-fluid border border-rounded mb-3 mb-md-0"
                                                                            :src="$imgURL + '/imagens/indisponivel.jpg'"
                                                                            alt="">
                                                                    </div>
                                                                </div>
                                                                <div class="col-6 col-md-4 d-flex align-items-center">
                                                                    <div class="descricao-content">
                                                                        <h6 class="subtitulo">{{ produto.pdiNomeProduto
                                                                        }}</h6>
                                                                        <p class="texto-mensagem"> Código: {{
                                                                            produto.proId }}</p>
                                                                        <p class="texto-mensagem"> Referência: {{
                                                                            produto.proProdutos.proReferencia }} </p>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6 col-md-1 d-flex align-items-center">
                                                                    <div class="descricao-content">
                                                                        <h6 class="subtitulo">{{ produto.qtdeProduto
                                                                        }}</h6>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6 col-md-2 d-flex align-items-center">
                                                                    <div class="descricao-content">
                                                                        <h6 class="subtitulo" style="font-size: 15px;">
                                                                            R$ {{
                                                                                parseFloat(produto.valorProduto).toFixed(2).toString().replace('.', ',')
                                                                            }}</h6>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6 col-md-2 d-flex align-items-center">
                                                                    <div class="descricao-content">
                                                                        <h6 class="subtitulo" style="font-size: 15px;">
                                                                            R$ {{
                                                                                parseFloat(produto.valorTotalProduto).toFixed(2).toString().replace('.', ',')
                                                                            }}</h6>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6 col-md-1 d-flex align-items-center">
                                                                    <a href="#" data-toggle="tooltip" data-placement="top" title="Excluir" @click.prevent="ExcluiProduto(index)">
                                                                        <i class="fa fa-window-close" style="color: black;font-size: 13px;"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-for="(adicional,index) in ListaPedidoItemAdicional"
                                                            :key="adicional.piaId"
                                                            class="descricao-pedido border-top py-3">
                                                            <div class="row">
                                                                <div
                                                                    class="col-3 col-md-2 d-flex align-items-center justify-content-center">
                                                                    <div class="descricao-img">
                                                                        <img v-if="adicional.proProdutosAvulso.proProdutosAvulsosImagens != null"
                                                                            class="img-fluid border border-rounded mb-3 mb-md-0"
                                                                            style="width:80%;"
                                                                            :src="$imgURL + adicional.proProdutosAvulso.proProdutosAvulsosImagens[0].paiUrlImagem"
                                                                            alt="">
                                                                        <img v-else
                                                                            class="img-fluid border border-rounded mb-3 mb-md-0"
                                                                            style="width:70%;"
                                                                            :src="$imgURL + '/imagens/indisponivel.jpg'"
                                                                            alt="">
                                                                    </div>
                                                                </div>
                                                                <div class="col-6 col-md-4 d-flex align-items-center">
                                                                    <div class="descricao-content">
                                                                        <h6 class="subtitulo">{{
                                                                            adicional.proProdutosAvulso.praNomeProduto }}</h6>
                                                                        <p class="texto-mensagem"> Código: {{
                                                                            adicional.proProdutosAvulso.praReferencia }}
                                                                        </p>
                                                                        <div>
                                                                            <input maxlength="40" v-if="adicional.proProdutosAvulso.praDestaque" type="text" class="form-control" v-model="adicional.proProdutosAvulso.praCampoExtra1" placeholder="Texto Balão">
                                                                            <button v-if="adicional.proProdutosAvulso.praAceitaImg" class="btn btn-info" @click.prevent="openModal(index)" style="padding: 3px !important;min-width: 70px !important">Escolher arte</button>
                                                                            <small v-if="adicional.proProdutosAvulso.praCampoExtra2!=''" class="texto-mensagem" style="font-size: 12px;">
                                                                                <a :href="$imgURL + adicional.proProdutosAvulso.praCampoExtra2" download target="_blank">
                                                                                    <img :src='$imgURL + adicional.proProdutosAvulso.praCampoExtra2' style="width: 80px;" />
                                                                                </a>
                                                                            </small>
                                                                            <button v-if="adicional.proProdutosAvulso.praAceitaImg" class="btn btn-info" @click.prevent="openModalFoto(index)">Incluir Foto</button>
                                                                            <small v-if="adicional.proProdutosAvulso.praCampoExtra3!=''" class="texto-mensagem" style="font-size: 12px;">
                                                                                <a :href="$imgURL + adicional.proProdutosAvulso.praCampoExtra3" download target="_blank">
                                                                                    <img :src='$imgURL + adicional.proProdutosAvulso.praCampoExtra3' style="width: 50px;" />
                                                                                </a>
                                                                                <v-btn icon density="compact" color="red" class="delete-icon" variant="text">
                                                                                    <v-icon @click="adicional.proProdutosAvulso.praCampoExtra3 = ''" style="font-size:22px;">mdi-alpha-x-circle</v-icon>
                                                                                </v-btn>
                                                                            </small>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6 col-md-1 d-flex align-items-center">
                                                                    <div class="descricao-content">
                                                                        <h6 class="subtitulo">{{
                                                                            adicional.qtdeProdutoAdicional }}</h6>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6 col-md-2 d-flex align-items-center">
                                                                    <div class="descricao-content">
                                                                        <h6 class="subtitulo" style="font-size: 15px;">
                                                                            R$ {{
                                                                                parseFloat(adicional.valorProdutoAdicional).toFixed(2).toString().replace('.', ',')
                                                                            }}</h6>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6 col-md-2 d-flex align-items-center">
                                                                    <div class="descricao-content">
                                                                        <h6 class="subtitulo" style="font-size: 15px;">
                                                                            R$ {{
                                                                                parseFloat(adicional.valorTotalProdutoAdicional).toFixed(2).toString().replace('.', ',')
                                                                            }}</h6>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6 col-md-1 d-flex align-items-center">
                                                                    <a href="#" data-toggle="tooltip" data-placement="top" title="Excluir"
                                                                        @click.prevent="ExcluiAvulso(index)">
                                                                        <i class="fa fa-window-close"
                                                                            style="color: black;font-size: 13px;"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                  </v-row>
                                               </v-card>
                                               <v-card color="#f7f7f7" style="padding: 15px;margin-top: 20px;">
                                                  <v-row>
                                                    <v-col cols="12" md="12">
                                                        <div class="d-flex align-items-center justify-content-between">
                                                             <v-text-field v-model="ValorDesconto" type="number" @input="calculatotal()"
                                                                @change="ValorDesconto != null || ValorDesconto != '' ? (ValorDesconto = parseFloat(ValorDesconto,10)) : (ValorDesconto = 0.0)"
                                                                label="Valor Desconto" variant="underlined" required></v-text-field>
                                                            </div>
                                                    </v-col>
                                                    <v-col cols="12" md="12">
                                                            <div class="d-flex align-items-center justify-content-between">
                                                                <v-text-field v-model="ValorFrete" type="number" @input="calculatotal()"
                                                                @change="ValorFrete != null || ValorFrete != '' ? (ValorFrete = parseFloat(ValorFrete,10)) : (ValorFrete = 0.0)"
                                                                label="Valor Frete" variant="underlined" required></v-text-field>
                                                            </div>
                                                    </v-col>
                                                    <v-col cols="12" md="12">
                                                            <div class="d-flex align-items-center justify-content-between">
                                                                <h5 class="subtitulo">Total</h5>
                                                                <h6 class="subtitulo">R$ {{
                                                                    parseFloat(TotalPedido).toFixed(2).toString().replace('.', ',')
                                                                }}</h6>
                                                            </div>
                                                    </v-col>
                                                    <v-col cols="12" md="12">
                                                        <v-select v-model="FormaPgto" label="Forma Pagamento"
                                                                :items="Pagamentos" item-title="nome"
                                                                item-value="valor" variant="underlined">
                                                        </v-select>
                                                    </v-col>
                                                  </v-row>
                                                </v-card>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="4" style="text-align: right;">
                                            <button type="button" @click="LimparMemoria()" class="btn btn-link">
                                                Limpar Memória
                                            </button>
                                        </v-col>
                                        <v-col cols="12" md="4" style="text-align: center;">
                                            <button type="button" class="btn btn-info" @click="SalvarMemoria(1)">
                                                Salvar Memória
                                            </button>
                                        </v-col>
                                        <v-col cols="12" md="4" style="text-align: left;">
                                            <button type="button" class="btn btn-success" @click="GerarPedido()">
                                                Gerar Pedido
                                            </button>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>

                        </v-window-item>
                    </v-window>
                </div>
            </div>
        </div>
        <v-container fluid>
        <v-row justify="center">
            <v-dialog v-model="dialogArtes" fullscreen :scrim="false" transition="dialog-bottom-transition" :style="{ 'background-color': 'rgba(0, 0, 0, 0.5)' }">
            <v-card>
                <!-- Toolbar fixo -->
                <v-toolbar fixed style="z-index: 9999999;position: relative;">
                <v-btn icon dark @click="dialogArtes = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title v-if="ListaArtesCateg!=''" style="padding: 5px;padding-top: 5px;">
                    <v-select-wrapper>
                        <select class="styled-select fontstyle" v-model="CategArteSelected" @change="FiltraCategArtes()" style="cursor: pointer;">
                            <option value="" disabled selected>Selecione Categoria</option>
                            <option v-for="item in ListaArtesCateg" :key="item.sgiId" :value="item.sgiId">{{ item.sgiNomeSegmento }}</option>
                        </select>
                        <span class="caret"></span>
                    </v-select-wrapper>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn variant="text" class="btn btn-success" @click="GravarArtes();" style="font-size:15px;color: #fff;
    background-color: #218838;
    border-color: #1e7e34;">Concluir</v-btn>
                </v-toolbar-items>
                </v-toolbar>
                <!-- Conteúdo com rolagem -->
                <v-card-text style="height: calc(100vh - 56px); overflow-y: auto;">
                <v-item-group v-model="ArteSelected">
                    <v-container>
                    <v-row>
                        <v-col v-for="mens in artes" :key="mens.itiId" cols="6" md="3">
                        <v-item>
                            <v-card 
                            class="d-flex align-center" 
                            dark height="230" 
                            @click="toggle(mens)"
                            :class="{ 'border-green': ArteSelected === mens }"
                            >
                            <div class="flex-grow-1 text-center" style="justify-content: center;">
                                <img :src="$imgURL + mens.insInstitucionalImagens[0].itgUrlImagem" style="height: 150px;" class="mx-auto" />
                                {{ mens.itiNome }}
                            </div>
                            <v-icon v-if="ArteSelected === mens" class="check-icon">mdi-check</v-icon>
                            </v-card>
                        </v-item>
                        </v-col>
                    </v-row>
                    </v-container>
                </v-item-group>
                </v-card-text>
            </v-card>
            </v-dialog>
        </v-row>
    </v-container>
    <v-row justify="center">
                        <v-dialog v-model="dialogFoto" class="controlcel">
                        <v-card style="height: 600px;">
                            <v-card-text style="padding: 16px 0px 10px">
                            <v-container>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" md="12">
                                            <span class="text-h5">Adicione uma imagem (Opcional - formato Quadrada)</span>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-file-input v-model="selectedFileFoto" label="Imagem" variant="underlined"
                                                prepend-icon="mdi-account-circle" accept="image/*"></v-file-input>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="UrlImagem !== '' && UrlImagem !== null">
                                            <img :src="$imgURL + UrlImagem" style="height: 350px" />
                                            <v-btn icon density="compact" color="red" class="delete-icon" variant="text">
                                                <v-icon @click="DeleteImagem()" style="font-size:22px;">mdi-alpha-x-circle</v-icon>
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="12" sm="4" md="6" v-if="UrlImagem==''">
                                            <a href="#"  variant="text" @click.prevent="salvarImagem();" class="btn btn-success px-md-5 mt-0 mb-3" style="color:#ffffff;">
                                                Salvar Imagem
                                            </a>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-container>
                            </v-card-text>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="black" variant="text" @click="dialogFoto = false">
                                Cancelar
                            </v-btn>
                            </v-card-actions>
                        </v-card>
                        </v-dialog>
        </v-row>
         <!--modal mensagens-->
         <v-row justify="center">
            <v-dialog v-model="dialogMensagens" class="controlcel">
            <v-card style="height: 600px;overflow-y: scroll;">
                <v-card-title>
                <span class="text-h5">Sugestões de Mensagens</span>
                </v-card-title>
                <v-card-text style="padding: 16px 0px 10px">
                <v-container>
                    <v-row v-for="(mens, index) in mensagens" :key="mens.itiId" :class="index % 2 === 0 ? 'white-row' : 'light-gray-row'">
                    <v-col cols="12" sm="8" md="8" >
                        <h3 style="font-size: 20px;">{{ mens.itiNome }}</h3>
                        <p v-html="mens.itiResumo1"></p>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" class="d-flex align-center">
                        <v-btn variant="text" @click="updateMensagem(mens.itiResumo1);dialogMensagens = false" class="btn btn-info" style="color:#ffffff;">
                        Escolher
                        </v-btn>
                    </v-col>
                    </v-row>
                </v-container>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="black" variant="text" @click="dialogMensagens = false">
                    Fechar
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import moment from 'moment'
import 'moment-timezone';
import libphonenumber from 'google-libphonenumber';

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();


export default {
    name: 'PedidoManualView',
    components: {
        EasyDataTable,
        moment
    },
    data: () => ({
        tab: null,
        NomeTela: 'Pedidos',
        InfoTela: 'Pedido Manual',
        CadastroTelas: [{ cdtId: 28, cdtNome: "Pedido Manual" }],
       //Clientes
       vshow: false,
           Clientes: {
            cliId: 0,
            cctId: "",
            cliVisivel: '',
            cliDataCadastro: '',
            cliNomeEmpresa: '',
            cliRazaoSocial: '',
            cliCnpj: '',
            cliCpf: '',
            cliTelefone: '',
            cliWhatsapp: '',
            cliResponsavel: '',
            cliEmail: '',
            cliSenha: '',
            cliAtivo: '',
            cliDataNascimento: '',
            cliCampoExtra1: '',
            cliCampoExtra2: '',
            cliCampoExtra3: '',
            cliCampoExtra4: '',
            cliCampoExtra5: '',
            cliTexto1: '',
            cliTexto2: '',
            cliTexto3: '',
            cliData1: '',
            cliData2: '',
        },
        ListClientes: [],

        //Clientes
        //endereco
        Estados: [],
        selectedEstados:null,
        Cidades: [],
        selectedCidades:null,
        Bairros: [],
        selectedBairros:null,
        BairroTexto:'',
        Endereco:'',
        Numero:'',
        Complemento:'',
        DataEntrega:'',
        valorFrete:0,
        NomePara: '',
        NomePara2: '',
        recebeEntrega: 'true',
        valorCupom:0,
        TelefoneRecebe:'',
        Observacao:'',
        retirarNaLoja: false,
        textosemhorario:'',
        //endereco
        //mensagens
        logintopo: [],
        Formulario: {
            NomeDe: '',
            NomePara: '',
            Mensagem: '',
        },
        AbreSenha: false,
        AbreCad: false,
        contadorCaracteres: 0,
        EnviarCartaoBranco: false,
        SemCartao: false,
        dialogMensagens: false,
        mensagens:[],
        //mendagens
        //entregas
        Entregas : {
            dataEntrega: '',
            horaIni:'',
            minIni:'',
            horaFim:'',
            minFim:'',
        },
        StatusHora: [
            { nome: "0", valor: 0 },
            { nome: "1", valor: 1 },
            { nome: "2", valor: 2 },
            { nome: "3", valor: 3 },
            { nome: "4", valor: 4 },
            { nome: "5", valor: 5 },
            { nome: "6", valor: 6 },
            { nome: "7", valor: 7 },
            { nome: "8", valor: 8 },
            { nome: "9", valor: 9 },
            { nome: "10", valor: 10 },
            { nome: "11", valor: 11 },
            { nome: "12", valor: 12 },
            { nome: "13", valor: 13 },
            { nome: "14", valor: 14 },
            { nome: "15", valor: 15 },
            { nome: "16", valor: 16 },
            { nome: "17", valor: 17 },
            { nome: "18", valor: 18 },
            { nome: "19", valor: 19 },
            { nome: "20", valor: 20 },
            { nome: "21", valor: 21 },
            { nome: "22", valor: 22 },
            { nome: "23", valor: 23 },
        ],
        StatusMin: [
            { nome: "00", valor: 0 },
            { nome: "15", valor: 15 },
            { nome: "30", valor: 30 },
            { nome: "45", valor: 45 },
        ],
        EntregaFrete: [],
        //entregas
        //pedido
          Pedidos: [],
          PedidosCli: [],
          TotalPedido: 0,
          ValorDesconto:0,
          ValorFrete:0,
          ListaPedidoItem: [],
          ListaPedidoItemAdicional: [],
          Situacoes: [],
        //pedido
        //produtos
        ListProdutosGeral:[],
        selectedItemProduto: null,
        ListProdutosAvulsosGeral:[],
        selectedItemProdutoAdicional: null,
        QtdeProdutoAdicional:'',
        ValorProdutoAdicional:'',
        QtdeProduto:'',
        ValorProduto:'',
        Pagamentos:[
            { nome: 'Maquina Física de Cartão',valor: '1'},
            { nome: 'Gerar link de Pagamento',valor: '2'},
            { nome: 'Pix chave Manual',valor: '3'},
            { nome: 'Pagamento Dinheiro',valor: '4'},
        ],
        FormaPgto:'',
        //produtos
        DadosCompra: {
            cliente:[],
            produtos: [],
            mensagem: [],
            agenda: [],
            foto: [],
            avulsos: [],
            baloes: [],
            informacao: [],
            pagamento: [],
            cupom: [],
        },
        linkPagamento: null,
        //pagamentos
        //artes
        dialogArtes: false,
        dialogFoto: false,
        artes:[],
        artesGeral:[],
        ArteSelected: '',
        ArteSelectedImg: '',
        controleindex:'',
        controleindexFoto:'',
        ListaArtesCateg:[],
        CategArteSelected:'',
        UrlImagem:'',
        selectedFileFoto:'',
       //artes 
       Paises: [],
        selectedPais:null,
    }),
    methods: {
        formatValorProduto() {
            if (this.ValorProduto != null && this.ValorProduto !== '') {
                // Substitui a vírgula por ponto
                let valor = this.ValorProduto.replace(',', '.');
                // Converte para float e formata com duas casas decimais
                valor = parseFloat(valor).toFixed(2);
                // Substitui o ponto por vírgula novamente
                this.ValorProduto = valor.toString().replace('.', ',');
            } else {
                this.ValorProduto = '0,00';
            }
        },
        formatValorProdutoAdicional() {
            if (this.ValorProdutoAdicional != null && this.ValorProdutoAdicional !== '') {
                // Substitui a vírgula por ponto
                let valor = this.ValorProdutoAdicional.replace(',', '.');
                // Converte para float e formata com duas casas decimais
                valor = parseFloat(valor).toFixed(2);
                // Substitui o ponto por vírgula novamente
                this.ValorProdutoAdicional = valor.toString().replace('.', ',');
            } else {
                this.ValorProdutoAdicional = '0,00';
            }
        },
        //clients
        PesquisaCliente(){
           if (this.Clientes.cliEmail == '' || this.Clientes.cliEmail == null){
            this.$mensagemAviso("Campo E-mail deve ser preenchido");
                this.Clientes.cliNomeEmpresa = "";
                this.Clientes.cliWhatsapp = "";
                this.Clientes.cliId = 0;
                this.vshow = false;
           }
           else{
            let varcli = this.ListClientes.filter(x => x.cliEmail.toLowerCase() === this.Clientes.cliEmail.toLowerCase())[0];
            if (varcli == '' || varcli == null){
                this.$mensagemAviso("Cliente novo.")
                this.Clientes.cliNomeEmpresa = "";
                this.Clientes.cliWhatsapp = "";
                this.Clientes.cliId = 0;
                this.vshow = false;
            }
            else{
                this.$mensagemAviso("Cliente já cadastrado.")
                this.Clientes.cliId = varcli.cliId;
                this.Clientes.cliNomeEmpresa = varcli.cliNomeEmpresa;
                this.Clientes.cliWhatsapp = varcli.cliWhatsapp;
                this.vshow = true;
            }
             }
        },
        validarNumeroCli() {
        // Remover espaços em branco e caracteres desnecessários
            let numeroTelefone = this.Clientes.cliWhatsapp.trim();

        try {
            // Parse do número de telefone
            const parsedNumber = phoneUtil.parse(numeroTelefone, 'ZZ');
            
            // Verificar se o número é válido
            if (phoneUtil.isValidNumber(parsedNumber)) {
            this.aplicarMascara(); // Formatar o número de telefone
            } else {
            this.$mensagemAviso('Número de telefone inválido');
            this.Clientes.cliWhatsapp = ''; // Limpar o campo
            this.$refs.whatsAppInput2.focus(); // Voltar o foco para o campo
            }
        } catch (e) {
            console.error('Erro ao processar o número de telefone:', e);
            this.Clientes.cliWhatsapp = ''; // Limpar o campo
            this.$refs.whatsAppInput2.focus(); // Voltar o foco para o campo
        }
        },
        aplicarMascaracli() {
            let input = this.Clientes.cliWhatsapp.replaceAll("(","").replaceAll(")","").replaceAll("-","");
            if (input.length == 11 && this.Clientes.cliWhatsapp.indexOf("+") == -1)
            {
                input = "+55" + this.Clientes.cliWhatsapp;
            }
            else if (input.length > 11 && this.Clientes.cliWhatsapp.indexOf("+") == -1)
            {
                input = "+" + this.Clientes.cliWhatsapp;
            }
            else{
                input = this.Clientes.cliWhatsapp;
            }
            

            try {
                const parsedNumber = phoneUtil.parseAndKeepRawInput(input, 'ZZ');
                const isValid = phoneUtil.isValidNumber(parsedNumber);

                if (isValid) {
                const formattedNumber = phoneUtil.format(parsedNumber, libphonenumber.PhoneNumberFormat.INTERNATIONAL);
                this.Clientes.cliWhatsapp = formattedNumber;
                return true;
                } else {
                    this.Clientes.cliWhatsapp = ''; // Limpar o campo
                    return false;
                }
            } catch (e) {
                this.Clientes.cliWhatsapp = ''; // Limpar o campo
                return false;
            }
        },
        //clientes
        //endereco
        validarNumero() {
        // Remover espaços em branco e caracteres desnecessários
            let numeroTelefone = this.TelefoneRecebe.trim();

        try {
            // Parse do número de telefone
            const parsedNumber = phoneUtil.parse(numeroTelefone, 'ZZ');
            
            // Verificar se o número é válido
            if (phoneUtil.isValidNumber(parsedNumber)) {
            this.aplicarMascara(); // Formatar o número de telefone
            } else {
            this.$mensagemAviso('Número de telefone inválido');
            this.TelefoneRecebe = ''; // Limpar o campo
            this.$refs.whatsAppInput.focus(); // Voltar o foco para o campo
            }
        } catch (e) {
            console.error('Erro ao processar o número de telefone:', e);
            this.TelefoneRecebe = ''; // Limpar o campo
            this.$refs.whatsAppInput.focus(); // Voltar o foco para o campo
        }
        },
        aplicarMascara() {
            let input = this.TelefoneRecebe.replaceAll("(","").replaceAll(")","").replaceAll("-","");
            if (input.length == 11 && this.TelefoneRecebe.indexOf("+") == -1)
            {
                input = "+55" + this.TelefoneRecebe;
            }
            else if (input.length > 11 && this.TelefoneRecebe.indexOf("+") == -1)
            {
                input = "+" + this.TelefoneRecebe;
            }
            else{
                input = this.TelefoneRecebe;
            }
            

            try {
                const parsedNumber = phoneUtil.parseAndKeepRawInput(input, 'ZZ');
                const isValid = phoneUtil.isValidNumber(parsedNumber);

                if (isValid) {
                const formattedNumber = phoneUtil.format(parsedNumber, libphonenumber.PhoneNumberFormat.INTERNATIONAL);
                this.TelefoneRecebe = formattedNumber;
                return true;
                } else {
                    this.TelefoneRecebe = ''; // Limpar o campo
                    return false;
                }
            } catch (e) {
                this.TelefoneRecebe = ''; // Limpar o campo
                return false;
            }
        },
        CarregaEstados(value) {
            const param1 = value;
            axios.get(`/Process/CadEstados/GetByPaisId/${param1}`)
                .then(response => {
                    this.Estados = response.data.filter(x => x.cetAtivo).sort((a, b) => {
                    const nomeA = a.cetNome || ""; 
                    const nomeB = b.cetNome || ""; 
                    return nomeA.localeCompare(nomeB);
                    });
    
                    if (this.Estados.length == 1) {
                        this.selectedEstados = this.Estados[0].cetId;
                        this.CarregaCidades(this.selectedEstados)
                    }
                // console.log('this.Estados')
               // console.log(this.Estados)

                });
        },
        CarregaCidades(value) {
            this.DataEntrega = null;
            this.ArrayEntregas = [];
            this.selectedHorarioId = [];
            this.selectedBairros = null;
            this.BairroTexto = '';
            const param1 = value;
            axios.get(`/Process/CadCidades/GetByEstadoId/${param1}`)
                .then(response => {
                    this.Cidades = response.data.filter(x => x.cidAtivo).sort((a, b) => {
                                                                                    const nomeA = a.cidNome || ""; 
                                                                                    const nomeB = b.cidNome || ""; 
                                                                                    return nomeA.localeCompare(nomeB);
                                                                                    });

                });
        },
        CarregaBairros(value) {
            this.selectedBairros = null;
            this.BairroTexto = '';
            this.DataEntrega = null;
            this.ArrayEntregas = [];
            this.selectedHorarioId = [];

            const param1 = value;
            axios.get(`/Process/CadBairros/GetByCidadeId/${param1}`)
                .then(response => {
                    this.Bairros = response.data.filter(x => x.baiAtivo).sort((a, b) => {
                    const nomeA = a.baiNome || ""; 
                    const nomeB = b.baiNome || ""; 
                    return nomeA.localeCompare(nomeB);
                    }); 
                    
                    if (this.Bairros.length > 0){
                      this.BairroTexto = '';
                    }
                    else{
                        this.AtualizaData(1);
                    }
                });
             
        },
        formatPhoneNumber(){
           const phoneNumber = this.TelefoneRecebe.replace(/\D/g, ''); // Remove todos os caracteres não numéricos

                if (phoneNumber.length <= 11) {
                // Se a quantidade de dígitos for menor que 11, assume-se que é um número brasileiro
                this.TelefoneRecebe = phoneNumber.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
                } else {
                // Caso contrário, não aplica nenhuma máscara
                this.TelefoneRecebe = phoneNumber;
                }
        },
        async preencherEnderecoFixo() {
            if (this.retirarNaLoja) {
                // Preencher com o endereço fixo quando "Retirar na loja" for marcado
                this.Endereco = 'Av. Dante Angelo Pilla';
                this.Numero = 91;
                this.Complemento = 'Loja';
                this.selectedEstados = 21;
                await this.CarregaCidades( this.selectedEstados)
                this.selectedCidades = 1;
                await this.CarregaBairros(this.selectedCidades)
                this.BairroTexto = '';
                this.selectedBairros = 42;
                this.ValorFrete = 0;
                // ... outros campos fixos ...
            } else {
                // Limpar os campos se o checkbox for desmarcado
                this.Endereco = '';
                this.Numero = '';
                this.Complemento = '';
                this.selectedCidades = null;
                this.selectedBairros = null;
                this.BairroTexto = '';
                this.CarregaCidades( this.selectedEstados);
                this.ValorFrete = 0;
                // ... limpar outros campos ...
            }
        },
        AtualizaData(value){
            if (value == 1){
                this.ValorFrete = this.EntregaFrete.filter(x=> x.cidId == this.selectedCidades)[0].eftValorFrete;
            }
            else{
                this.ValorFrete = this.EntregaFrete.filter(x=> x.cidId == this.selectedCidades && x.baiId == this.selectedBairros)[0].eftValorFrete;
            }
            this.calculatotal();
        },
        //endereco
        //mensagens
        updateMensagem(value) {
        // Usando a propriedade innerText para obter apenas o texto sem HTML
        this.Formulario.Mensagem = this.stripHTML(value);
        },
        stripHTML(html) {
        let doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
        },
        preencherEnviarCartaoBranco(){
        this.SemCartao = false;
        this.Formulario.Mensagem = "";
        this.Formulario.NomeDe = "";
        this.Formulario.NomePara = "";
        this.contadorCaracteres = 0;
        },
        preencherSemCartao(){
        this.EnviarCartaoBranco = false;
        this.Formulario.Mensagem = "";
        this.Formulario.NomeDe = "";
        this.Formulario.NomePara = "";
        this.contadorCaracteres = 0;
        },
        limitarCaracteres() {
        if (this.Formulario.Mensagem.length > 800) {
            this.Formulario.Mensagem = this.Formulario.Mensagem.substring(0, 800);
        }
        this.contadorCaracteres = this.Formulario.Mensagem.length;
        },
        limparPlaceholder(event) {
        event.target.placeholder = '';
        },
        restaurarPlaceholder(event) {
        event.target.placeholder = 'Mensagem (*Não esqueça de assinar)';
        },
        //mensagens
        //pedido
        LimparMemoria(){
            localStorage.removeItem(this.$DadosCompraManual);
            this.$mensagemAviso("Memória Limpa.");
            this.Clientes.cliNomeEmpresa = "";
            this.Clientes.cliWhatsapp = "";
            this.Clientes.cliEmail = "";
            this.Clientes.cliId = 0;
            this.vshow = false;
            this.QtdeProduto = "";
            this.ValorProduto = "";
            this.QtdeProdutoAdicional = "";
            this.ValorProdutoAdicional = "";
            this.selectedItemProdutoAdicional = "";
            this.selectedItemProduto = "";
            this.ListaPedidoItemAdicional = [];
            this.ListaPedidoItem = [];
            this.ValorFrete = 0;
            this.ValorDesconto = 0;
            this.FormaPgto = "";
        this.selectedCidades = null;
        this.selectedBairros=null;
        this.BairroTexto='';
        this.Endereco='';
        this.Numero='';
        this.Complemento='';
        this.DataEntrega='';
        this.NomePara='';
        this.NomePara2='';
        this.recebeEntrega=false;
        this.valorCupom=0;
        this.TelefoneRecebe='';
        this.Observacao='';
        this.retirarNaLoja= false;
        this.textosemhorario='';
        this.Formulario.NomeDe='';
        this.Formulario.NomePara='';
        this.Formulario. Mensagem='';
        this.Entregas.dataEntrega='';
        this.Entregas.horaIni='';
        this.Entregas.minIni='';
        this.Entregas.horaFim='';
        this.Entregas.minFim='';
        this.TotalPedido = 0;
        this.Get();
        },
        SalvarMemoria(value){
            localStorage.removeItem(this.$DadosCompraManual);
            this.DadosCompra.cliente = [];
            this.DadosCompra.produtos = [];
            this.DadosCompra.mensagem = [];
            this.DadosCompra.agenda = [];
            this.DadosCompra.foto = [];
            this.DadosCompra.avulsos = [];
            this.DadosCompra.baloes = [];
            this.DadosCompra.informacao = [];
            this.DadosCompra.pagamento = [];
            this.DadosCompra.cupom = [];

            //cliente
            this.DadosCompra.cliente.push(this.Clientes);

            //entrega
                let _agenda = {
                    Endereco: this.Endereco,
                    Numero: this.Numero,
                    Complemento: this.Complemento,
                    Estado: this.selectedEstados,
                    Cidade: this.selectedCidades,
                    Bairro: this.selectedBairros,
                    BairroTexto: this.BairroTexto,
                    DataEntrega: this.Entregas.dataEntrega,
                    HoraIni: this.Entregas.horaIni,
                    MinIni: this.Entregas.minIni,
                    HoraFim: this.Entregas.horaFim,
                    MinFim: this.Entregas.minFim,
                    TelefoneRecebe: this.TelefoneRecebe,
                    NomePara:  this.NomePara,
                    NomePara2:  this.NomePara2,
                   // MesmoRecebe: this.recebeEntrega,
                    ObsCliente: this.Observacao,
                    RetirarLoja: this.retirarNaLoja,
                    ValorFrete: this.ValorFrete,
                }
                this.DadosCompra.agenda.push(_agenda);

                //mensagem
                let _mensagem = {
                NomeDe: this.Formulario.NomeDe,
                NomePara: this.Formulario.NomePara,
                Mensagem: this.Formulario.Mensagem,
                SemCartao: this.SemCartao,
                CartaoBranco: this.EnviarCartaoBranco
                }

                if (this.SemCartao == true){
                _mensagem.Mensagem = "Sem Cartão";
                _mensagem.NomeDe = "";

                }
                if (this.EnviarCartaoBranco == true){
                _mensagem.Mensagem = "Enviar Cartão em Branco"
                _mensagem.NomeDe = "";
                }
                this.DadosCompra.mensagem.push(_mensagem);

                //produto
                this.DadosCompra.produtos.push(this.ListaPedidoItem);

                //produto adicional
                this.DadosCompra.avulsos.push(this.ListaPedidoItemAdicional);

                //desconto
                this.DadosCompra.cupom.push({tipo: 'Carro', valorcupom: this.ValorDesconto})

                //pagamento
                this.DadosCompra.pagamento.push({codigo: this.FormaPgto});

            localStorage.setItem(this.$DadosCompraManual, JSON.stringify(this.DadosCompra));
            if (value == 1){
                this.$mensagemAviso("Salvo em memória.")
            }
            
        },
        async GerarPedido(){
         await this.SalvarMemoria(2);

         let dadosArmazenados = localStorage.getItem(this.$DadosCompraManual);
        if (dadosArmazenados) {
            dadosArmazenados = JSON.parse(dadosArmazenados);
            if (dadosArmazenados.cliente[0].cliNomeEmpresa == ''){
                this.$mensagemAviso("Preencha nome cliente.")
            }
            else if (dadosArmazenados.cliente[0].cliWhatsapp == ''){
                this.$mensagemAviso("Preencha whatsapp cliente.")
            }
            else if (dadosArmazenados.cliente[0].cliEmail == ''){
                this.$mensagemAviso("Preencha E-mail cliente.")
            }
            else if (dadosArmazenados.agenda[0].DataEntrega == ''){
                this.$mensagemAviso("Preencha data entrega.")
            }
            else if (dadosArmazenados.agenda[0].HoraIni === "" || dadosArmazenados.agenda[0].HoraIni === null){
                this.$mensagemAviso("Preencha hora inicial entrega.")
            }
            else if (dadosArmazenados.agenda[0].MinIni === "" || dadosArmazenados.agenda[0].MinIni === null){
                this.$mensagemAviso("Preencha minuto inicial entrega.")
            }
            else if (dadosArmazenados.agenda[0].HoraFim === "" || dadosArmazenados.agenda[0].HoraFim === null){
                this.$mensagemAviso("Preencha hora final entrega.")
            }
            else if (dadosArmazenados.agenda[0].MinFim === "" || dadosArmazenados.agenda[0].MinFim === null){
                this.$mensagemAviso("Preencha minuto final entrega.")
            }
            else if (dadosArmazenados.agenda[0].Estado == '' || dadosArmazenados.agenda[0].Estado == null){
                this.$mensagemAviso("Preencha Estado entrega.")
            }
            else if (dadosArmazenados.agenda[0].Cidade == '' || dadosArmazenados.agenda[0].Cidade == null){
                this.$mensagemAviso("Preencha Cidade entrega.")
            }
            else if (dadosArmazenados.agenda[0].Bairro == null && dadosArmazenados.agenda[0].BairroTexto == ""){
                this.$mensagemAviso("Selecione o Bairro.")
            }
            else if (dadosArmazenados.agenda[0].Endereco == ''){
                this.$mensagemAviso("Preencha endereço entrega.")
            }
            else if (dadosArmazenados.agenda[0].Numero == ""){
                this.$mensagemAviso("Preencha o Número de entrega.")
            }
            else if (dadosArmazenados.agenda[0].Complemento == ""){
                this.$mensagemAviso("Preencha o Complemento (casa, apto, escritório...) de entrega.")
            }
            else if (this.recebeEntrega == "false" && dadosArmazenados.agenda[0].NomePara2 == ""){
                this.$mensagemAviso("Preencha o nome de quem recebe.")
            }
            else if (dadosArmazenados.agenda[0].TelefoneRecebe == ""){
                this.$mensagemAviso("Preencha o Telefone de quem vai receber a entrega.")
            }
            else if (dadosArmazenados.mensagem[0].SemCartao == false && dadosArmazenados.mensagem[0].CartaoBranco == false && dadosArmazenados.mensagem[0].Mensagem == ""){
                this.$mensagemAviso("Por favor preencha a mensagem ou selecione as opções sem mensagem.");
            }
            else if (dadosArmazenados.produtos[0].length == 0){
                this.$mensagemAviso("Adicione um produto na lista.")
            }
            else if (dadosArmazenados.pagamento[0].codigo == ""){
                this.$mensagemAviso("Selecione Pagamento.")
            }
            else{

                let _result = await this.aplicarMascara();

                if (_result)
                {
                    let _result2 = await this.aplicarMascaracli();

                    if (_result2)
                    {
                    this.CarregaPedido();
                    }
                    else{
                        this.$mensagemAviso('Número de whatsapp inválido');
                    }
                }
                else{
                    this.$mensagemAviso('Número Telefone que recebe inválido');
                }
              
            }
        }
        },
        calculatotal() {
            var valorAvulsos = 0;
            var valorProdutos = 0;
            var vcount = 0;

                if (this.ListaPedidoItemAdicional != '') {

                    this.ListaPedidoItemAdicional.forEach(item => {
                        valorAvulsos += item.valorTotalProdutoAdicional;
                        vcount++;
                    });
                }

                if (this.ListaPedidoItem != '') {

                    this.ListaPedidoItem.forEach(item => {
                        valorProdutos += item.valorTotalProduto;
                        vcount++;
                    });
                }
               // console.log('valorProdutos')
               // console.log(valorProdutos)
               // console.log('valorAvulsos')
               // console.log(valorAvulsos)
               // console.log('ValorFrete')
               // console.log(parseFloat(this.ValorFrete))
               // console.log('ValorDesconto')
               // console.log(parseFloat(this.ValorDesconto))
                this.TotalPedido = (valorProdutos + valorAvulsos + parseFloat(this.ValorFrete)) - parseFloat(this.ValorDesconto);
           
        },
        ExcluiAvulso(index) {
            this.ListaPedidoItemAdicional.splice(index, 1);
            this.calculatotal();
        },
        ExcluiProduto(index) {
            this.ListaPedidoItem.splice(index, 1);
            this.calculatotal();
        },
        async criarLinkPagamento(ListProdutos, ListAvulsos, vDesconto, vFrete, vEmail, vCliente) {
            const accessToken = 'APP_USR-3966920645518105-101316-0c64cb4ab62d8c495b613584811247bb-1403059465'; // Token de acesso do Mercado Pago

            let vitem = [];

            for (let index = 0; index < ListProdutos.length; index++) {
                let valorProdutoString = ListProdutos[index].valorProduto || ""
                    vitem.push({
                        title: ListProdutos[index].proProdutos.proNomeProduto,
                        quantity: parseInt(ListProdutos[index].qtdeProduto),
                        currency_id: 'BRL',
                        unit_price: parseFloat(valorProdutoString.replaceAll('.', '').replaceAll(',', '.')),
                    })                   
                }

                for (let index = 0; index < ListAvulsos.length; index++) {
                   let valorProdutoString = ListAvulsos[index].qtdeProdutoAdicional || ""
                    vitem.push({
                        title: ListAvulsos[index].proProdutosAvulso.praNomeProduto,
                        quantity: parseInt(valorProdutoString.replaceAll('.', '').replaceAll(',', '.')),
                        currency_id: 'BRL',
                        unit_price: parseFloat(ListAvulsos[index].valorProdutoAdicional),
                    })                   
                }

                if (vFrete != 0){
                    vitem.push({
                        title: 'Frete',
                        quantity: 1,
                        currency_id: 'BRL',
                        unit_price: parseFloat(vFrete),
                    })                   
                }

                if (vDesconto != 0){
                    vitem.push({
                        title: 'Desconto',
                        quantity: 1,
                        currency_id: 'BRL',
                        unit_price: -parseFloat(vDesconto),
                    })                   
                }


            // Dados do pagamento
            const paymentData = {
                items: vitem,
                external_reference: vCliente, // Referência externa para identificar a transação
                payer: {
                email: vEmail, // Email do cliente
                },
                paymentMethods: {
                    creditCard: "all",
                    bankTransfer: ['pix'],
                    maxInstallments: 6
                },
                back_urls: {
                    success: 'https://cestasportoalegre.com/success',
                    pending: 'https://cestasportoalegre.com/pending',
                    failure: 'https://cestasportoalegre.com/failure',
                    },
                auto_return: 'approved',
            };

           // console.log('paymentData')
           // console.log(paymentData)

           try {
                const response = await axios.post('https://api.mercadopago.com/checkout/preferences', paymentData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                });
                if (response.status === 200 || response.status === 201) {
                  
                 this.linkPagamento = response.data.init_point;

                } else {
                   console.error('Erro ao criar link de pagamento:', response.statusText);
                }

                return this.linkPagamento;

            } catch (error) {
                console.error('Erro ao criar link de pagamento:', error);
            }
        },
        async CarregaPedido(){
                let dadosArmazenados = localStorage.getItem(this.$DadosCompraManual);
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    let _situacao = 'Aguardando';
                    let _pspId = 1;
                    let _clienteId = 0;

                   if (dadosArmazenados.cliente[0].cliId == 0){
                   
                    let vClientes =  {
                        cliId: 0,
                        cctId: 1,
                        cliClienteTipo: { CctId: 1 },
                        cliVisivel: true,
                        cliDataCadastro: this.$moment().format('YYYY-MM-DD'),
                        cliNomeEmpresa: this.Clientes.cliNomeEmpresa,
                        cliRazaoSocial: '',
                        cliCnpj: '',
                        cliCpf: '',
                        cliTelefone: '',
                        cliWhatsapp: this.Clientes.cliWhatsapp,
                        cliResponsavel: '',
                        cliEmail: this.Clientes.cliEmail,
                        cliSenha: '',
                        cliAtivo: true,
                        cliDataNascimento: null,
                        cliCampoExtra1: '',
                        cliCampoExtra2: '',
                        cliCampoExtra3: '',
                        cliCampoExtra4: '',
                        cliCampoExtra5: '',
                        cliTexto1: '',
                        cliTexto2: '',
                        cliTexto3: '',
                        cliData1: null,
                        cliData2: null,
                    };

                    console.log('cliente')
                    console.log(vClientes)
                    await axios.post(`/Process/CliClientes/Add`, vClientes)
                        .then(response => {
                            if (response.data > 0) { 
                                _clienteId = response.data;
                            }
                        });
                   }
                   else{
                    _clienteId = dadosArmazenados.cliente[0].cliId;
                   }

        let vFormaPgto = "";
        if (dadosArmazenados.pagamento[0].codigo == "1")
        {
            vFormaPgto = 'credit_card Físico'
        }
        if (dadosArmazenados.pagamento[0].codigo == "2")
        {
            vFormaPgto = 'payment_card Link'
        }
        if (dadosArmazenados.pagamento[0].codigo == "3")
        {
            vFormaPgto = 'bank_transfer Físico'
        }
        if (dadosArmazenados.pagamento[0].codigo == "4")
        {
            vFormaPgto = 'payment_cash Físico'
        }
        
        var vlinkPgto = "";
        var linkpgto = "";
        if (dadosArmazenados.pagamento[0].codigo == "2"){
          let vcupom = parseFloat(dadosArmazenados.cupom[0].valorcupom);
          let vFrete = parseFloat(dadosArmazenados.agenda[0].ValorFrete);
            linkpgto = await this.criarLinkPagamento(dadosArmazenados.produtos[0], dadosArmazenados.avulsos[0],vcupom,vFrete,dadosArmazenados.cliente[0].cliEmail,_clienteId);
        }
        else{
            linkpgto = dadosArmazenados.pagamento[0].codigo;
        }

        if (dadosArmazenados.pagamento[0].codigo == "1"){
            vlinkPgto = 'Maquina Física de Cartão';
        }
        if (dadosArmazenados.pagamento[0].codigo == "2"){
            vlinkPgto = linkpgto;
        }
        if (dadosArmazenados.pagamento[0].codigo == "3"){
            vlinkPgto = 'Pix chave Manual';
        }
        if (dadosArmazenados.pagamento[0].codigo == "4"){
            vlinkPgto = 'Pagamento Dinheiro';
        }

                    //dados do pedido
                        let _pedido = {
                            PedId: 0,
                            GrtId: 7,
                            CdtId: 28,
                            IdiId: 1,
                            PedDataPedido: moment().format('YYYY-MM-DD'),
                            PedOrigemPedido: 'Manual',
                            CliId: _clienteId,
                            PedFormaPgto: vFormaPgto,
                            PedTipoFrete: dadosArmazenados.agenda[0].RetirarLoja == true ? 'Retirar Loja' : 'Carro',
                            PedValorFrete: parseFloat(dadosArmazenados.agenda[0].ValorFrete),
                            CupId : 0,
                            PedCodigoCupom: '',
                            PedValorCupom: parseFloat(dadosArmazenados.cupom[0].valorcupom),
                            PedObsLoja: '',
                            PedRetornoPgto: 'pending',
                            PedCodigoRetornoPgto: vlinkPgto, //aqui vai link
                            PspId: _pspId,
                            PedSituacaoPedido: _situacao,
                            PedObsCliente: dadosArmazenados.agenda[0].ObsCliente
                            }
                            console.log('_pedido')
                            console.log(_pedido)

                        await axios.post(`/Process/PedPedidos/Add`, _pedido)
                                    .then(response => {
                                        if (response.data > 0) {
                                                _pedido.PedId = response.data;   
                                                this.NumeroPedido = _pedido.PedId;
                                                console.log('_pedido adicionado')
                                               // console.log(_pedido)                
                                        }
                                    });

                            //itens do pedido
                            let _pedidoItens = [];
                            if (dadosArmazenados.produtos[0].length > 0) {
                                dadosArmazenados.produtos[0].forEach((itemprod) => {
                                _pedidoItens.push(
                                    {
                                        PdiId: 0,
                                        GrtId: 7,
                                        CdtId: 28,
                                        IdiId: 1,
                                        PedId: _pedido.PedId,
                                        ProId: itemprod.proProdutos.proId,
                                        PdiNomeProduto: itemprod.proProdutos.proNomeProduto,
                                        PdiQtdeProduto: parseInt(itemprod.qtdeProduto),
                                        PdiValorProduto: parseFloat(itemprod.valorProduto),
                                        PdiValorTotalProduto: itemprod.valorTotalProduto,
                                        PdiDataCadastro: moment().format('YYYY-MM-DD'),
                                        PdiCampoExtra2: itemprod.proProdutos.proCampoExtra2,
                                    }
                                );
                                });
                            }

                            console.log('_pedidoItens')
                            console.log(_pedidoItens)

                           await _pedidoItens.forEach((itens) => {
                            axios.post(`/Process/PedPedidoItem/Add`, itens)
                                    .then(response => {
                                        if (response.data > 0) {
                                        console.log('_pedidoItens adicionado')
                                        //console.log(itens)    
                                        axios.put(`/Process/ProProdutosPrecos/UpdateEstoque/`+ itens.ProId + '/' + itens.PdiQtdeProduto)
                                            .then(response => {
                                                if (response.data > 0) {
                                                    console.log('Estoque atualizado')        
                                                }
                                            });
                                        }
                                    });
                            })

                            //itens adicional do pedido
                            let _pedidoItensAvulsos = [];
                            if (dadosArmazenados.avulsos[0].length > 0) {
                                console.log('_pedidoItensAvulsos')
                                console.log(_pedidoItensAvulsos)

                                dadosArmazenados.avulsos[0].forEach((itemprod) => {
                                let valorProdutoString = itemprod.valorProdutoAdicional || "0";
                                valorProdutoString = String(valorProdutoString);
                                console.log('valorProdutoString')
                                console.log(valorProdutoString)


                                _pedidoItensAvulsos.push(
                                    {
                                        PiaId: 0,
                                        GrtId: 7,
                                        CdtId: 28,
                                        IdiId: 1,
                                        PedId: _pedido.PedId,
                                        PraId: itemprod.proProdutosAvulso.praId,
                                        PiaNomeProduto: itemprod.proProdutosAvulso.praNomeProduto,
                                        PiaQtdeProduto: parseInt(itemprod.qtdeProdutoAdicional),
                                        PiaValorProduto: parseFloat(valorProdutoString.replaceAll('.', '').replaceAll(',', '.')),
                                        PiaValorTotalProduto: parseFloat(itemprod.valorTotalProdutoAdicional),
                                        PiaDataCadastro: moment().format('YYYY-MM-DD'),
                                        PiaCampoExtra1: itemprod.proProdutosAvulso.praCampoExtra1,   //texto balao
                                        PiaCampoExtra2: itemprod.proProdutosAvulso.praCampoExtra2,  // arte
                                        PiaCampoExtra3: itemprod.proProdutosAvulso. praCampoExtra3,  //foto
                                    }
                                );
                                });

                                console.log('_pedidoItensAvulsos')
                                console.log(_pedidoItensAvulsos)

                               await _pedidoItensAvulsos.forEach((itens) => {
                                axios.post(`/Process/PedPedidoItemAdicional/Add`, itens)
                                    .then(response => {
                                        if (response.data > 0) {
                                            console.log('_pedidoItensAvulsos adicionado')
                                           // console.log(itens)         
                                        }
                                    });
                            })
                            }
                        

                        var _PagHoraIni = dadosArmazenados.agenda[0].HoraIni;
                        var _PagMinIni = dadosArmazenados.agenda[0].MinIni;
                        var _PagHoraFim = dadosArmazenados.agenda[0].HoraFim;
                        var _PagMinFim = dadosArmazenados.agenda[0].MinFim;

                     
                            //pedido agendamento
                            let _pedidoAgendamento = {
                            PagId: 0,
                            GrtId: 7,
                            CdtId: 28,
                            IdiId: 1,
                            PagDiaEntrega: dadosArmazenados.agenda[0].DataEntrega,
                            PagHoraIni: _PagHoraIni,
                            PagMinIni: _PagMinIni,
                            PagHoraFim: _PagHoraFim,
                            PagMinFim: _PagMinFim,
                            PagUrlImagemEntrega:'',
                            PedId:_pedido.PedId,
                            }

                            console.log('_pedidoAgendamento')
                            console.log(_pedidoAgendamento)

                           await axios.post(`/Process/PedPedidoAgendamento/Add`, _pedidoAgendamento)
                                    .then(response => {
                                        if (response.data > 0) {   
                                                console.log('_pedidoAgendamento adicionado')
                                                //console.log(_pedidoAgendamento)                
                                        }
                                    });


                            //pedido Endereço
                            let _pedidoEndereco = {
                                PdcId: 0,
                                GrtId: 7,
                                CdtId: 28,
                                IdiId: 1,
                                PdcEndereco: dadosArmazenados.agenda[0].Endereco,
                                PdcNumero: parseInt(dadosArmazenados.agenda[0].Numero,10),
                                PdcComplemento: dadosArmazenados.agenda[0].Complemento,
                                PdcObsEndereco:'',
                                pdcCep:'',
                                PdcPais:'',
                                PdcEstado:'',
                                PdcCidade:'',
                                PdcBairro: dadosArmazenados.agenda[0].BairroTexto,
                                CpaId:1,
                                CetId: dadosArmazenados.agenda[0].Estado!= null ? parseInt(dadosArmazenados.agenda[0].Estado,10): 0,
                                CidId: dadosArmazenados.agenda[0].Cidade != null ? parseInt(dadosArmazenados.agenda[0].Cidade,10): 0,
                                BaiId: dadosArmazenados.agenda[0].Bairro != null ? parseInt(dadosArmazenados.agenda[0].Bairro,10) : 0,
                                PedId:_pedido.PedId,
                            }

                            console.log('_pedidoEndereco')
                            console.log(_pedidoEndereco)

                            await axios.post(`/Process/PedPedidoEndereco/Add`, _pedidoEndereco)
                                    .then(response => {
                                        if (response.data > 0) {   
                                                console.log('_pedidoEndereco adicionado')
                                                //console.log(_pedidoEndereco)                
                                        }
                                    });


                            if (dadosArmazenados.mensagem != '') {

                                let _recebeCesta = dadosArmazenados.mensagem[0].NomePara;

                                _recebeCesta +=
                                " - (Quem vai receber: " +
                                dadosArmazenados.agenda[0].NomePara2 +
                                " )";

                            //pedido Mensagem
                            let _pedidoMensagem = {
                                PmsId: 0,
                                GrtId: 7,
                                CdtId: 28,
                                IdiId: 1,
                                PmsUrlImagem: '',
                                PmsNomeDe: dadosArmazenados.mensagem[0].NomeDe,
                                PmsNomePara: _recebeCesta,
                                PmsTexto: dadosArmazenados.mensagem[0].Mensagem,
                                PmsTelefoneRecebe: dadosArmazenados.agenda[0].TelefoneRecebe,
                                PedId:_pedido.PedId,
                            }

                            console.log('_pedidoMensagem')
                            console.log(_pedidoMensagem)

                            await axios.post(`/Process/PedPedidoMensagem/Add`, _pedidoMensagem)
                                    .then(response => {
                                        if (response.data > 0) {   
                                                console.log('_pedidoMensagem adicionado')
                                                //console.log(_pedidoMensagem)                
                                        }
                                    });
                            }
                         
                            
                            this.$mensagemAviso("Pedido Inserido.")
                            localStorage.removeItem(this.$DadosCompraManual);
                            this.Clientes.cliNomeEmpresa = "";
                            this.Clientes.cliWhatsapp = "";
                            this.Clientes.cliEmail = "";
                            this.Clientes.cliId = 0;
                            this.vshow = false;
                            this.QtdeProduto = "";
                            this.ValorProduto = "";
                            this.QtdeProdutoAdicional = "";
                            this.ValorProdutoAdicional = "";
                            this.selectedItemProdutoAdicional = "";
                            this.selectedItemProduto = "";
                            this.ListaPedidoItemAdicional = [];
                            this.ListaPedidoItem = [];
                            this.ValorFrete = 0;
                            this.ValorDesconto = 0;
                            this.FormaPgto = "";
                            this.$router.push('/listapedidos')
                           
                            
                        } 
        },
        //pedido
        //produtos
        AddProduto(){
            var qtdeprod = this.QtdeProduto == '' ? 0 : this.QtdeProduto;
            this.selectedItemProduto.qtdeProduto = qtdeprod;
            this.selectedItemProduto.valorTotalProduto = qtdeprod * parseFloat(this.ValorProduto.replace(',','.'),10);
            this.selectedItemProduto.valorProduto = this.ValorProduto.replace(',','.');
            this.ListaPedidoItem.push(this.selectedItemProduto)
            this.QtdeProduto = "";
            this.ValorProduto = "";
            this.selectedItemProduto = "";
            this.calculatotal();
        },
        AddProdutoAdicional(){
            var qtdeprodAdi = this.QtdeProdutoAdicional == '' ? 0 : this.QtdeProdutoAdicional;
            this.selectedItemProdutoAdicional.qtdeProdutoAdicional = qtdeprodAdi;
            this.selectedItemProdutoAdicional.valorTotalProdutoAdicional = qtdeprodAdi * parseFloat(this.ValorProdutoAdicional.replace(',','.'),10);
            this.selectedItemProdutoAdicional.valorProdutoAdicional = this.ValorProdutoAdicional.replace(',','.');
            this.ListaPedidoItemAdicional.push(this.selectedItemProdutoAdicional)
            this.QtdeProdutoAdicional = "";
            this.ValorProdutoAdicional = "";
            this.selectedItemProdutoAdicional = "";
            this.calculatotal();
        },
        pegaValorProduto(){
        if (this.selectedItemProduto != "")
        {
          this.ValorProduto =  this.formatarValor(this.selectedItemProduto.proProdutos.proValorPor,10);
          axios.get('/Process/ProAvulsosporProdutos/GetAllByProId/' + this.selectedItemProduto.proProdutos.proId)
                .then(response => {
                    this.ListProdutosAvulsosGeral = response.data.filter(x=> x.proProdutosAvulso!=null && x.proProdutosAvulso.proProdutosAvulsosImagens!=null);
                    this.ListProdutosAvulsosGeral =  this.ListProdutosAvulsosGeral.sort((a, b) => {
                                                                const valorA = parseInt(a.proProdutosAvulso.praOrdem) || 0;
                                                                const valorB = parseInt(b.proProdutosAvulso.praOrdem) || 0;
                                                                return valorA - valorB;
                                                                });
                      this.ListProdutosAvulsosGeral = this.ListProdutosAvulsosGeral.map(item => {
                                return {
                                    ...item,
                                    nomeConcatenado: `${item.proProdutosAvulso.praId} - ${item.proProdutosAvulso.praNomeProduto} - ${item.proProdutosAvulso.praReferencia}`
                                };
                        });   
                        
                       // console.log('this.ListProdutosAvulsosGeral')
                       // console.log(this.ListProdutosAvulsosGeral)
                    
                })
                .catch(error => {
                    console.error(error);
                });
        }
        
        },
        pegaValorProdutoAdicional(){
           if (this.selectedItemProdutoAdicional != "")
            {
            this.ValorProdutoAdicional =  this.formatarValor(this.selectedItemProdutoAdicional.proProdutosAvulso.praValorPor,10);
            }
        },
        //produtos
        //artes
        GravarArtes() {
               if (this.ArteSelected == '' && this.ListaPedidoItemAdicional[this.controleindex].proProdutosAvulso.praCampoExtra2 == ''){
                    this.$mensagemAviso("Selecione uma arte.")
                }
                else{      
                    if (this.ArteSelected!=''){
                        this.ListaPedidoItemAdicional[this.controleindex].proProdutosAvulso.praCampoExtra2 = this.ArteSelected.insInstitucionalImagens[0].itgUrlImagem;
                    }       
                    this.controleindex = '';
                        this.dialogArtes=false;
                
                }
        },
        openModal(value){
            this.controleindex = value;
            this.dialogArtes = true;
        },
        toggle(mes) {
            this.ArteSelected = mes
        },
        FiltraCategArtes(){
          this.artes = this.artesGeral.filter(x=> x.insInstitucionalSegmentos.some(w=> w.insSegmentos.sgiId == this.CategArteSelected))
        },
        openModalFoto(value){
            //console.log('this.ListaPedidoItemAdicional')
            //console.log(this.ListaPedidoItemAdicional)
            this.controleindexFoto = value;
            this.dialogFoto = true;
            this.UrlImagem = this.ListaPedidoItemAdicional[this.controleindexFoto].proProdutosAvulso.praCampoExtra3;
        },
        async UploadImg(value) {
            const formData = new FormData();
            formData.append('file', value);
            const uploadResult = await axios.post(`/Process/UploadImg/upload`, formData);
            return uploadResult;
        },
        async salvarImagem(){
            if (this.selectedFileFoto != '' && this.selectedFileFoto != null) {
                    const uploadSuccess = await this.UploadImg(this.selectedFileFoto[0]);
                    if (uploadSuccess.status == 200) {
                        this.UrlImagem = uploadSuccess.data.nameFile;
                        this.ListaPedidoItemAdicional[this.controleindexFoto].proProdutosAvulso.praCampoExtra3  = this.UrlImagem;
                        this.selectedFileFoto = null;
                        this.dialogFoto = false;
                    }
                    else {
                        this.$mensagemErro("Não foi possível carregar imagem.")
                    }
                }
        },
        DeleteImagem(){
            this.UrlImagem = "";
            this.ListaPedidoItemAdicional[this.controleindexFoto].praCampoExtra3 = ""
        },
        //artes
        formattedDate(value) {
            if (value != null || value != "") {
                return this.$moment(value).format('DD/MM/YYYY')
            }
            else {
                return "";
            }

        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        async Get() {
          
            await this.CarregaEstados(76);

            axios.get('/Process/InsInstitucional/institucional/28/cadastromensagens')
                .then(response => {
                    this.mensagens = response.data;
                // console.log('this.logintopo')
                // console.log(this.logintopo)
                })
                .catch(error => {
                    console.error(error);
                });

            axios.get('/Process/InsInstitucional/institucional/26/textomensagem')
                .then(response => {
                    this.logintopo = response.data;
                // console.log('this.logintopo')
                // console.log(this.logintopo)
                })
                .catch(error => {
                    console.error(error);
                });

                axios.get(`/Process/CliClientes/GetAll`)
                        .then(response => {
                            this.ListClientes = response.data;                         
                        });

                axios.get(`/Process/PedCadSituacaoPedido/GetAll`)
                .then(response => {
                    this.Situacoes = response.data
                    //console.log('this.Situacoes')
                    //console.log(this.Situacoes)
                });

              await  axios.get('/Process/ProProdutosSegmentos/GetAllBySite')
                    .then(response => {
                        this.ListProdutosGeral = response.data;
                        this.ListProdutosGeral = this.ListProdutosGeral.map(item => {
                            return {
                                ...item,
                                nomeConcatenado: `${item.proProdutos.proId} - ${item.proProdutos.proNomeProduto} - ${item.proProdutos.proReferencia}`
                            };
                        });

                        // Fazer a distinção dos itens com base na propriedade nomeConcatenado
                        const distinctItems = this.ListProdutosGeral.filter((item, index, self) =>
                            index === self.findIndex(t => (
                                t.nomeConcatenado === item.nomeConcatenado
                            ))
                        );
                        this.ListProdutosGeral = distinctItems.sort((a, b) => {
                            const nomeA = a.proProdutos.proNomeProduto || "";
                            const nomeB = b.proProdutos.proNomeProduto || "";
                            return nomeA.localeCompare(nomeB);
                        });
                        //console.log('Itens distintos:');
                       // console.log(distinctItems);

                    })
                    .catch(error => {
                        console.error(error);
                    });

                    axios.get(`/Process/EntEntregaFrete/GetAll`)
                    .then(response => {
                        this.EntregaFrete = response.data;
                        //console.log('EntregaFrete')
                     //console.log(this.EntregaFrete)
                    });


                axios.get('/Process/InsInstitucional/institucional/29/cadastroartes')
                .then(response => {
                    this.artesGeral = response.data.filter(x=> x.itiVisivel).sort((a, b) => a.itiOrdem - b.itiOrdem);
                    this.artes =this.artesGeral;
                })
                .catch(error => {
                    console.error(error);
                });

                axios.get('/Process/InsSegmentos/GetAll')
                .then(response => {
                    this.ListaArtesCateg = response.data.sort((a, b) => a.itiNome - b.itiNome);

                })
                .catch(error => {
                    console.error(error);
                });

                await  axios.get(`/Process/CadPaises/GetAll`)
                .then(response => {
                    this.Paises = response.data.sort((a, b) => {
                                                            const nomeA = a.cpaNome || ""; 
                                                            const nomeB = b.cpaNome || ""; 
                                                            return nomeA.localeCompare(nomeB);
                                                            });
               // console.log('this.Paises')
               // console.log(this.Paises)
               this.selectedPais = 76;
                });


                let dadosArmazenados = localStorage.getItem(this.$DadosCompraManual);
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    if (dadosArmazenados.cliente != ''){
                        this.Clientes.cliId = dadosArmazenados.cliente[0].cliId;
                        this.Clientes.cliNomeEmpresa = dadosArmazenados.cliente[0].cliNomeEmpresa;
                        this.Clientes.cliWhatsapp = dadosArmazenados.cliente[0].cliWhatsapp;
                        this.Clientes.cliEmail = dadosArmazenados.cliente[0].cliEmail;
                        if (this.Clientes.cliId == 0){
                            this.vshow = false;
                        }
                        else{
                            this.vshow = true;
                        }
                        if (dadosArmazenados.agenda != ''){
                            //endereco
                            this.retirarNaLoja = dadosArmazenados.agenda[0].RetirarLoja;
                           
                           if (dadosArmazenados.agenda[0].Cidade != null && dadosArmazenados.agenda[0].Cidade != "") {
                            this.selectedEstados = dadosArmazenados.agenda[0].Estado;
                            this.selectedCidades = dadosArmazenados.agenda[0].Cidade;
                            await this.CarregaBairros(this.selectedCidades);
                            this.selectedBairros = dadosArmazenados.agenda[0].Bairro;
                           }                          
                           
                            this.Endereco = dadosArmazenados.agenda[0].Endereco;
                            this.Numero = dadosArmazenados.agenda[0].Numero;
                            this.Complemento = dadosArmazenados.agenda[0].Complemento;
                            this.BairroTexto = dadosArmazenados.agenda[0].BairroTexto;
                            this.Entregas.dataEntrega = dadosArmazenados.agenda[0].DataEntrega;
                            this.Entregas.horaIni  = dadosArmazenados.agenda[0].HoraIni;
                            this.Entregas.minIni  = dadosArmazenados.agenda[0].MinIni;
                            this.Entregas.horaFim  = dadosArmazenados.agenda[0].HoraFim;
                            this.Entregas.minFim  = dadosArmazenados.agenda[0].MinFim;
                            this.TelefoneRecebe  = dadosArmazenados.agenda[0].TelefoneRecebe;
                            //this.recebeEntrega = dadosArmazenados.agenda[0].MesmoRecebe;
                            //this.NomePara = dadosArmazenados.agenda[0].NomePara;
                            this.NomePara2 = dadosArmazenados.agenda[0].NomePara2;
                            this.Observacao = dadosArmazenados.agenda[0].ObsCliente;
                            this.ValorFrete = parseFloat(dadosArmazenados.agenda[0].ValorFrete);
                        }



                        //mensagem
                        if (dadosArmazenados.mensagem != ''){
                            this.EnviarCartaoBranco = dadosArmazenados.mensagem[0].CartaoBranco;
                            this.SemCartao = dadosArmazenados.mensagem[0].SemCartao;
                            if (this.SemCartao == "true" || this.EnviarCartaoBranco == "true"){
                                this.Formulario.Mensagem = "";
                                this.Formulario.NomeDe = "";
                                this.Formulario.NomePara = "";
                            }
                            else{
                                this.Formulario.Mensagem = dadosArmazenados.mensagem[0].Mensagem;
                                this.Formulario.NomeDe = dadosArmazenados.mensagem[0].NomeDe;
                                this.Formulario.NomePara = dadosArmazenados.mensagem[0].NomePara;
                            }
                        }

                        //produtos
                        if (dadosArmazenados.produtos != ''){
                            this.ListaPedidoItem = dadosArmazenados.produtos[0];
                        }

                         //produtos adicionais
                         if (dadosArmazenados.avulsos != ''){
                            this.ListaPedidoItemAdicional = dadosArmazenados.avulsos[0];
                        }

                        //cupom
                        if (dadosArmazenados.cupom != ''){
                          this.ValorDesconto = dadosArmazenados.cupom[0].valorcupom;
                        }

                        //pagamento
                        if (dadosArmazenados.pagamento != ''){
                           this.FormaPgto = dadosArmazenados.pagamento[0].codigo;
                        }

                      this.calculatotal();
                    }
                    }
                    else{
                        this.calculatotal();
                    }
        }
    },
    created() {
        this.Get();
    },
}
</script>

<style scoped>.tabinfo {
    font-size: 14px;
    text-transform: capitalize;
}


td {
    border: 1px solid #333333;
    padding: 3px;
}

.small-text {
    font-size: 10px;
    color: #dc2626;
    margin-top: 0px !important;
    position: absolute;
}


@media (min-width:1200px) {

    .fonttext{
        font-size: 14px;
    }
    /* Estilos para o select */
.styled-select {
  display: block;
  width: 100%;
  font-size: 15px;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.altcel {
    min-height: 500px !important;
    height: auto !important;
}

.altavul {
    padding-top: 25px;
}

.btncel {
    color:#ffffff;
    min-width:80px !important;
    font-size: 18px;
}
}

@media (min-width:768px) {
    .altavul {
    padding-top: 35px;
}

.fonttext{
        font-size: 14px;
    }
    /* Estilos para o select */
.styled-select {
  display: block;
  width: 100%;
  font-size: 15px;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.altcel {
    min-height: 500px !important;
    height: auto !important;
}

.altavul {
    padding-top: 25px;
}

.btncel {
    color:#ffffff;
    min-width:80px !important;
    font-size: 18px;
}
}


@media (max-width:767px) {

    .fonttext{
        font-size: 12px;
    }

    .altcel {
    min-height: 300px !important;
    height: auto !important;
}
.altavul {
    padding-top: 5px;
}

.text-h5{
    font-size: 15px !important;
}

.btncel {
    color:#ffffff;
    min-width:80px !important;
    padding: 0 !important;
     font-size: 12px;
}

/* Estilos para o select */
.styled-select {
  display: block;
  width: 100%;
  font-size: 12px;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.v-toolbar-title__placeholder {
    overflow: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.v-toolbar-title {
    flex: auto !important;
    font-size: 1.25rem;
    min-width: 0;
    font-size: 1.25rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.75rem;
    text-transform: none;
}

}

.border-green {
    border: 4px solid green !important; /* Defina a cor e largura da borda */
    border-radius: 8px; /* Adicione um raio de borda para suavizar a aparência */
}

.check-icon {
    position: absolute;
    bottom: 5px; /* Ajuste conforme necessário */
    right: 5px; /* Ajuste conforme necessário */
    color: green;
    background-color: white;
    border-radius: 50%;
    padding: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5); /* Adiciona sombra para destaque */
}

    .v-dialog > .v-overlay__content {
        max-height: calc(100% - 128px);
        width: calc(100% - 48px);
        max-width: calc(100% - 6px) !important;
        margin: 24px;
        display: flex;
        flex-direction: column;
    }


    /* Estilos para o wrapper do select */
.v-select-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}



/* Estilos para o ícone de dropdown */
.caret {
  position: absolute;
  top: 50%;
  right: 0.75rem;
  transform: translateY(-50%);
  pointer-events: none;
}


</style>
